import React from 'react'
import { useTranslation } from "react-i18next";
const Services = () => {
    const { t } = useTranslation();

    return (
        <section className="our-service-sec">
            <div className="container">
                <div className="section-heading text-center">
                    <h3>{t("our-service-main-heading")}</h3>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="service-col">
                            <h4>{t("translation")}</h4>
                            <p>
                                {t("our-service-translation-paragraph")}
                            </p>
                            <h5>Some Characteristics:</h5>
                            <div className="row translate-service">
                                <div className="col-lg-6">
                                    <ul>
                                        <li>Academic paper: <span className={`characteristic-span`}>Detailed and precise translations maintaining the integrity of research and findings.</span></li>
                                        <li>Short communication: <span className={`characteristic-span`}>Quick and efficient translations for brief messages or announcements.</span></li>
                                        <li>Report or Transcript: <span className={`characteristic-span`}>Accurate translations of official records, retaining the original's structure and format.</span></li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li>Legal document: <span className={`characteristic-span`}>Confidential and accurate rendering of legal terms and clauses.</span></li>
                                        <li>Book: <span className={`characteristic-span`}>Preserving the author's voice and tone while ensuring cultural relevance. Report or Transcript: Clear translations that retain the format and structure of the original.</span></li>
                                        <li>Other: <span className={`characteristic-span`}> Customized solutions tailored to your unique requirements.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="service-col">
                            <h4>{t("proofReader")}</h4>
                            <p>
                                {t("our-service-proofReader-paragraph")}
                            </p>
                            <h5>Some Characteristics:</h5>
                            <ul>
                                <li>Academic paper: <span className={`characteristic-span`}>Thorough checks for clarity, consistency, and adherence to academic standards.</span></li>
                                <li>Legal document: <span className={`characteristic-span`}>Verification of legal terminologies and ensuring zero errors for official use.</span></li>
                                <li>Short communication: <span className={`characteristic-span`}>Quick reviews to ensure the message is concise and error-free.</span></li>
                                <li>Book: <span className={`characteristic-span`}>Comprehensive review maintaining the narrative flow and catching inconsistencies</span></li>
                                <li>Report or Transcript: <span className={`characteristic-span`}>Ensuring data accuracy and clear presentation for official records.</span></li>
                                <li>Other: <span className={`characteristic-span`}> Personalized proofreading services for diverse content types.</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services