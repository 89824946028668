import React, { Fragment, useEffect, useState } from "react";
import RecentProjects from "../../components/common/recentProjectSlider/RecentProjectsSlider";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData, getProfile, downloadAllPaidReceiptsReq, downloadAllUnPaidIvoicesReq } from "../../services/api/profileApi";
import { setJobCounts } from "../../redux/reducers/dashBoardSlice";
import { toast } from "react-toastify";
import SocialShare from "../../components/common/socialShare/SocialShare";
import {
  setSelectedJobDetails,
  setSelectedReceiptDetails,
} from "../../redux/reducers/jobSlice";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../../redux/reducers/authSlice";
import RecentTranscationSlider from "../../components/common/TransactionSlider/RecentTranscationSlider";
import WithDrawModelCustomer from "../../components/common/WithdrawModel/WithDrawModelCustomer";
import { checkWordCount } from "../../services/api/jobPostApis";
import {
  setActiveStep,
  updateBlobFile,
  updateJobPostFileWordCount,
} from "../../redux/reducers/jobPostSlice";
import DragDropUploadButton from "../../components/common/DragDropUploadButton";

import TableBusiness from "./TableBusiness";


const buttonStyle = {
  padding: "12px 20px",
  background: "#141d6d",
  color: "white",
  fontWeight: "bold",
  borderRadius: "5px",
};

const BusinessDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showWithDrawModel, setShowWithDrawModel] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const { user, company } = useSelector((state) => state.auth);
  const { cancelJobs, completedProjects, ongoingProjects } = useSelector(
    (state) => state.dashBoard
  );
  const [showSocialShare, setShowSocialShare] = useState(false);
  const { jobWordCount, uploadFiles, blobFile, fileDetails } = useSelector(
    (state) => state.jobPost
  );
  const downloadAllPaidReceipts = async () => {
    downloadAllPaidReceiptsReq();
    toast.success("Request sent to server successfully")
  };
  const downloadAllUnPaidInvoices = async () => {
    downloadAllUnPaidIvoicesReq();
    toast.success("Request sent to server successfully")
  };
  useEffect(() => {
    (async () => {
      let dashbordData = await getDashboardData();
      let payload = {
        cancelJobs: dashbordData?.result?.data?.cancelJobs,
        completedProjects: dashbordData?.result?.data?.completedProjects,
        ongoingProjects: dashbordData?.result?.data?.ongoingProjects,
      };
      dispatch(setJobCounts(payload));
    })();
  }, []);
  const getUserDetails = async () => {
    let resultData = await getProfile();
    dispatch(updateUser({ user: resultData?.result?.data }));
    setUserDetail(resultData?.result?.data);
  };

  useEffect(() => {
    console.log(company)
    getUserDetails();
  }, []);

  const wordCountCheck = async (data) => {
    let uploadFileData = await checkWordCount(data[0]);
    if (uploadFileData?.result) {
      dispatch(
        updateJobPostFileWordCount({
          count: uploadFileData?.result?.data?.wordCount,
        })
      );
      blobToBase64(data[0])
        .then((base64String) => {
          dispatch(
            updateBlobFile({
              blobFile: { b64: base64String, name: data[0]?.path },
            })
          );
          dispatch(setActiveStep({ activeStep: "uploadFile" }));
          navigate("/job-post/upload-file");
        })
        .catch((error) => {
          console.error("Error converting blob to base64:", error);
        });
    }
  };
  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  return (
    <Fragment>
      <div className="user-dashbord mb-5">
        <div className="section-header-dashbord common-btm-mrgn">
          <div className="container">
            {company && <div className="content">
              <div className="d-flex align-items-center gap-2">
              {company.logo && <img src={company.logo} alt="Company Logo" width={120} height={80} className="mr-3" />}
              <div className="mt-3 ">
              <h4 className="pt-1 mb-1">Welcome! {user.name}.</h4>
              <p className="text-secondary">
                <strong>{company.name}</strong> <strong>{company.companyCode}</strong> 
              </p>
              </div>
              </div>
            </div>}
          </div>
        </div>

        <div className="dashbord-three-box common-btm-mrgn">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sngl-box green-box">
                  <div className="zone">
                    <div id="dropZ">
                      <img src="/images/file.png" alt="" />
                      <div className="drag-text">
                        Drag & drop your entire document here.
                      </div>
                      <div className="drag-text">Supported file types: 
                      Images; Documents (.docx); Spreadsheets (.xlxs , .csv); PDF; Powerpoint (.pptx)</div>
                      <div className="pro-or">
                        <span> OR</span>
                      </div>

                      <DragDropUploadButton
                        btnCss={"upload-f"}
                        btnName={"Browse File"}
                        multiple={false}
                        disabled={!!blobFile}
                      />
                      <DragDropUploadButton
                        btnName={""}
                        multiple={false}
                        onChange={wordCountCheck}
                        disabled={!!blobFile}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-4">
                <div className="sngl-box red-box">
                  <h3><span><img src="/images/tickdb.svg" alt="" /></span> {cancelJobs}</h3>
                  <h5>Projects cancelled</h5>
                  <p>{cancelJobs}</p>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="dashbord-three-box common-btm-mrgn">
          <div className="container">
            {/* <div className="d-flex gap-3 mt-5">
              <button color="" style={buttonStyle} onClick={downloadAllUnPaidInvoices}>
                Download all unpaid Invoices
              </button>
              <button color="" style={buttonStyle} onClick={downloadAllPaidReceipts}>
                Download all Paid Receipts
              </button>
            </div> */}
          </div>
        </div>

        <div className="dashbord-three-box common-btm-mrgn">
          <div className="container">
            <TableBusiness />
          </div>
        </div>
      </div>
      {/*
        <RecentProjects gotoSelectedProject={gotoSelectedProject} />
        <RecentTranscationSlider gotoSelectedReceipt={gotoSelectedReceipt} />
      */}

      <SocialShare
        setShowSocialShare={setShowSocialShare}
        showSocialShare={showSocialShare}
      />
      {showWithDrawModel && (
        <WithDrawModelCustomer
          showWithDrawModel={showWithDrawModel}
          setShowWithDrawModel={setShowWithDrawModel}
        />
      )}
    </Fragment>
  );
};

export default BusinessDashboard;
