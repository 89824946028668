import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { checkPromoCode, createJob, createPayment } from '../../../services/api/jobPostApis'
import { useNavigate } from 'react-router-dom'
import ResponsiveStepper from './ResponsiveStepper'
import { setCartCurrency, setJobPrice, setPromoCode, setRewardPoint, setWalletFund } from '../../../redux/reducers/jobPostSlice'
import PromoCodeModal from '../../../components/common/promoCodeModal/PromoCodeModal'
import ReedemRewardpointModal from './ReedemRewardpointModal'
import ReedemWalletModal from './ReedemWalletModal'

const Payment = () => {
    const dispatch = useDispatch()
    const {
        jobWordCount,
        serviceType,
        configTranslation,
        configProofreading,
        jobPrice,
        selectedSpeedOption,
        rewardPointUsed,
        promoCode,
        walletFundUsed,
        cartCurrency,


    } = useSelector((state) => state.jobPost);
    const { user } = useSelector(state => state.auth)
    const [useReward, setUseReward] = useState(false)
    const [useWallet, setUseWallet] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [openReward, setOpenReward] = useState(false)
    const [usedReward, setUsedReward] = useState(0)
    const [openWallet, setOpenWallet] = useState(false)
    const [usedWallet, setUsedWallet] = useState(0)




    // const paylib = window.paylib


    const payForJob = async () => {
        let bodyData = {
            cartAmount: cartCurrency === "USD" ? jobPrice : jobPrice * 0.7,
            cartCurrency: cartCurrency,
            returnUrl: `${window.location.origin}`
        }
        let payment = await createPayment(bodyData)
        if (payment) {
            sessionStorage.setItem('transRef', payment?.result?.data?.tran_ref)
            window.location.href = payment?.result?.data?.redirect_url;
        }
    }
    const handleRedeem = () => {
        if (jobPrice > user?.rewardPoint) {
            setUseReward(!useReward)
        }
        else {
            toast.error('As reward point is greater than job price, you cannot redeem this')
        }
    }
    const handleRedeemWallet = () => {
        if (jobPrice > user?.affiliatedAmount) {
            setUseWallet(!useWallet)
        }
        else {
            toast.error('As wallet fund is greater than job price, you cannot redeem this')
        }
    }



    const handleJobPrice = () => {
        if ((useReward && useWallet)) {
            if (configProofreading.specService === 'Proofreading + Stylesheet Adherence') {
                dispatch(setJobPrice({
                    jobPrice: Number(Number((Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) - (usedReward + usedWallet)).toFixed(4)) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
                }))
            }
            // if (configTranslation?.chooseTranslator === 'Subject Specialized Translator') {
            //     dispatch(setJobPrice({
            //         jobPrice: (Number((Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) - (usedReward + usedWallet)).toFixed(4)) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
            //     }))
            // }
            // else if (configProofreading.specService === 'Proofreading + Stylesheet Adherence') {
            //     dispatch(setJobPrice({
            //         jobPrice: Number(Number((Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) - (usedReward + usedWallet)).toFixed(4)) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
            //     }))
            // }
            // else {
            //     dispatch(setJobPrice({
            //         jobPrice: Number(Number((Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy))).toFixed(4))) - (usedReward + usedWallet)).toFixed(4)) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
            //     }))
            // }
            dispatch(setRewardPoint({ rewardPointUsed: Number(usedReward) }))
            dispatch(setWalletFund({ walletFundUsed: Number(usedWallet) }))
        }
        else if ((!useReward && useWallet)) {
            if (configProofreading.specService === 'Proofreading + Stylesheet Adherence') {
                dispatch(setJobPrice({
                    jobPrice: Number(Number((Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) - (usedWallet)).toFixed(4)) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
                }))
            }
            // if (configTranslation?.chooseTranslator === 'Subject Specialized Translator') {
            //     dispatch(setJobPrice({
            //         jobPrice: (Number((Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) - (usedWallet)).toFixed(4)) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
            //     }))
            // }
            // else if (configProofreading.specService === 'Proofreading + Stylesheet Adherence') {
            //     dispatch(setJobPrice({
            //         jobPrice: Number(Number((Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) - (usedWallet)).toFixed(4)) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
            //     }))
            // }
            // else {
            //     dispatch(setJobPrice({
            //         jobPrice: Number(Number((Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy))).toFixed(4))) - (usedWallet)).toFixed(4)) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
            //     }))
            // }

            dispatch(setWalletFund({ walletFundUsed: Number(usedWallet) }))
            dispatch(setRewardPoint({ rewardPointUsed: 0 }))

        }
        else if (useReward) {
            if (configProofreading.specService === 'Proofreading + Stylesheet Adherence') {
                dispatch(setJobPrice({
                    jobPrice: Number(Number((Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) - usedReward).toFixed(4)) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
                }))
            }
            // if (configTranslation?.chooseTranslator === 'Subject Specialized Translator') {
            //     dispatch(setJobPrice({
            //         jobPrice: (Number((Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) - usedReward).toFixed(4)) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
            //     }))
            // }
            // else if (configProofreading.specService === 'Proofreading + Stylesheet Adherence') {
            //     dispatch(setJobPrice({
            //         jobPrice: Number(Number((Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) - usedReward).toFixed(4)) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
            //     }))
            // }
            // else {
            //     dispatch(setJobPrice({
            //         jobPrice: Number(Number((Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy))).toFixed(4))) - usedReward).toFixed(4)) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
            //     }))
            // }
            dispatch(setRewardPoint({ rewardPointUsed: Number(usedReward) }))
            dispatch(setWalletFund({ walletFundUsed: 0 }))

        }
        else if (!useReward) {
            if (configProofreading.specService === 'Proofreading + Stylesheet Adherence') {
                dispatch(setJobPrice({
                    jobPrice: Number(Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
                }))
            }
            // if (configTranslation?.chooseTranslator === 'Subject Specialized Translator') {
            //     dispatch(setJobPrice({
            //         jobPrice: Number(Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
            //     }))
            // }
            // else if (configProofreading.specService === 'Proofreading + Stylesheet Adherence') {
            //     dispatch(setJobPrice({
            //         jobPrice: Number(Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
            //     }))
            // }
            // else {
            //     dispatch(setJobPrice({
            //         jobPrice: Number(Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy))).toFixed(4))) - (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0))
            //     }))
            // }
            dispatch(setRewardPoint({ rewardPointUsed: 0 }))
            dispatch(setWalletFund({ walletFundUsed: 0 }))
        }
    }

    const discountAmount = () => {
        if (!promoCode || promoCode.discountAmount === 0) {
            return 0;
          }
        
          // Apply percentage discount
          if (promoCode.discountType === 'percentage') {
            return jobPrice * (1 - promoCode.discountAmount / 100);
          }
        
          // Apply fixed discount
          return jobPrice - promoCode.discountAmount;
        
        return promoCode ? promoCode.discountType === 'percentage' ? jobPrice * (1 - promoCode.discountAmount / 100) : jobPrice - promoCode.discountAmount : jobPrice;
        if (configTranslation?.chooseTranslator === 'Subject Specialized Translator') {
            return (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0)
        }
        else if (configProofreading.specService === 'Proofreading + Stylesheet Adherence') {
            return (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0)
        }
        else {
            return (promoCode ? (promoCode?.discountType === 'percentage' ? ((selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1)).toFixed(4))) * (promoCode?.discountAmount / 100)) : promoCode?.discountAmount) : 0)
        }
    }


    // Handle promocode
    const handlePromocode = async () => {
        handleJobPrice()
        let bodyData = {
            promoCode: document.getElementById('promocodeInput').value
        }
        let checkedPromoCode = await checkPromoCode(bodyData)
        if (!checkedPromoCode) {
            // toast.error('You can not use this promocode')
        }
        else {
            dispatch(setPromoCode({ promoCode: { ...checkedPromoCode, discountedAmmount: checkedPromoCode?.discountType === 'percentage' ? ((jobPrice * (checkedPromoCode?.discountAmount / 100))) : (checkedPromoCode?.discountAmount) } }))
            dispatch(setJobPrice({
                jobPrice: checkedPromoCode?.discountType === 'percentage' ? (jobPrice - (jobPrice * (checkedPromoCode?.discountAmount / 100))) : (jobPrice - checkedPromoCode?.discountAmount)
            }))
        }
    }

    useEffect(() => {
        handleJobPrice()
    }, [useReward, promoCode, useWallet, usedReward, usedWallet])


    const handleRewardClose = () => {
        setOpenReward(false)
    }

    const handleRewardAmmount = (value) => {
        if (value) {
            setUsedReward(Number(value))
            setUseReward(true)
        }
        else {
            setUsedReward(0)
            setUseReward(false)
        }
    }

    const handleWalletClose = () => {
        setOpenWallet(false)
    }

    const handleWalletAmmount = (value) => {
        if (value) {
            setUsedWallet(Number(value))
            setUseWallet(true)
        }
        else {
            setUsedWallet(0)
            setUseWallet(false)
        }
    }
    return (
        <>
            <ResponsiveStepper goNext={payForJob} />
            <div className="card2 first-screen show ">

                <div className="row">
                    <div className="col-lg-12">
                        <h4>Pay and Confirm your order</h4>
                    </div>
                    <div className="col-lg-8">
                        <div className="card2-col">

                            <div className="light-gray coupon-code1">
                                <div className="code-flex">
                                    <h6>Coupon Code:</h6>
                                    {promoCode ? <div className="coupon-code">
                                        {promoCode?.promoCode}
                                    </div> : null}
                                </div>
                                <div className="code-flex">
                                    {/* <form action="">
                                        <div className="form-group">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value=""
                                                    id="flexCheckDefault" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    Use Coupon
                                                </label>
                                            </div>

                                        </div>
                                    </form> */}
                                    <div className={`promocode_field`}>
                                        <input type='text' id='promocodeInput' />
                                        <button onClick={handlePromocode}>Apply</button>
                                    </div>
                                    {/* <div className="coupon-view" onClick={() => setShowModal(true)}>
                                        <a>View my coupons <i className="fas fa-angle-right"></i></a>
                                    </div> */}
                                </div>
                            </div>

                            <div className="light-gray Redeem-flex">
                                <div className="Redeem-flex-h">
                                    <h4>reward points</h4>
                                    <h5>{user?.rewardPoint - usedReward} reward points</h5>
                                </div>


                                <p className="btn" style={{ backgroundColor: useReward ? 'red' : '#9eb212' }} onClick={() => { !useReward ? setOpenReward(true) : setUseReward(false); setUsedReward(0); dispatch(setRewardPoint({ rewardPointUsed: 0 })) }}>{useReward ? 'Cancel' : 'Redeem'}</p>

                            </div>
                            <div className="light-gray Redeem-flex">
                                <div className="Redeem-flex-h">
                                    <h4>wallet fund</h4>
                                    <h5>{user?.affiliatedAmount - usedWallet} wallet fund</h5>
                                </div>
                                <p className="btn" style={{ backgroundColor: useWallet ? 'red' : '#9eb212' }} onClick={() => { !useWallet ? setOpenWallet(true) : setUseWallet(false); setUsedWallet(0); dispatch(setWalletFund({ walletFundUsed: 0 })) }}>{useWallet ? 'Cancel' : 'Redeem'}</p>

                            </div>

                            <div className="light-gray paymet-in-profile">
                                {/* <div className="radio-group">
                                    <input type="radio" name="plan" value="date1" />
                                    <label htmlFor="html">
                                        <div className="ch-flex">
                                            <h5>Credit or debit card</h5>
                                            <img src="/images/debit.png" alt="" />
                                        </div>
                                    </label>
                                </div>
                                <div className="radio-group">
                                    <input type="radio" name="plan" value="date1" />
                                    <label htmlFor="html">

                                        <div className="ch-flex">
                                            <h5>Paypal</h5>
                                            <img src="/images/paypal.png" alt="" />
                                        </div>

                                    </label>
                                </div> */}

                                <div className="f-info">
                                    {/* <h3>Credit card info</h3> */}
                                    {/* <form> */}

                                    <div className="row">
                                        <h5>Amount to pay</h5>
                                        <div className={`currency-toggle`}>
                                            <label>
                                                USD
                                                <input type='radio' id="USD" name="currency" value={"USD"} checked={cartCurrency === 'USD'} onChange={(e) => { dispatch(setCartCurrency({ cartCurrency: e.target.value })) }} />
                                            </label>
                                            <label>
                                                JOD
                                                <input type='radio' id="JOD" name='currency' value={"JOD"} checked={cartCurrency === 'JOD'} onChange={(e) => { dispatch(setCartCurrency({ cartCurrency: e.target.value })) }} />
                                            </label>
                                        </div>
                                        {/* <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="">Credit Card number</label>
                                                <input type="text" className="form-control"
                                                    placeholder="1234-4569-4642-9842" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="">Exp date</label>
                                                <input type="datetime-local" className="form-control"
                                                    placeholder="MM/YY" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="">CVV</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="">Name on Card</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div> */}
                                        <div className="tab-content">
                                            <div id="home" className={`tab-pane ${cartCurrency === "USD" && "active"}`}>
                                                <div className="row">

                                                    <div className="col-lg-12 mb-4 col-md-12">
                                                        <div className="tab-left-content">
                                                            <div className="tab-box-wrap">
                                                                ${jobPrice.toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* tab1 */}
                                            <div id="home" className={`tab-pane ${cartCurrency === "JOD" && "active"}`}>
                                                <div className="row">

                                                    <div className="col-lg-12 mb-4 col-md-12">
                                                        <div className="tab-left-content">
                                                            <div className="tab-box-wrap">
                                                                {(jobPrice * 0.7).toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* tab2 */}
                                        </div>
                                        <div className="pay-button">
                                            <button type="submit" onClick={payForJob} className="btn w-100">Pay</button>
                                        </div>

                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>


                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="gray-l">
                            <div className="order-dets">
                                <div className="o-flex">
                                    <h5>Order no:</h5>
                                    <p>5454511</p>
                                </div>
                                <div className="o-flex">
                                    <h5>Services:</h5>
                                    <p>{serviceType?.toUpperCase()}</p>
                                </div>
                                {promoCode ? <div className="o-flex">
                                    <h5>Coupon Code:</h5>
                                    <p>{promoCode?.promoCode}</p>
                                </div> : null}
                                <div className="o-flex">
                                    <h5>Costs:</h5>
                                    <p>${(jobPrice + rewardPointUsed + walletFundUsed + discountAmount()).toFixed(2)}</p>
                                 </div>
                                <div className="o-flex">
                                    <h5>Discount:</h5>
                                    <p>${discountAmount().toFixed(2)} {promoCode?.discountType === 'percentage' && `(${promoCode?.discountAmount})%`}</p>
                                </div>
                                <div className="o-flex">
                                    <h5>Reward points:</h5>
                                    <p>{rewardPointUsed} points</p>
                                </div>
                                <div className="o-flex">
                                    <h5>Wallet fund:</h5>
                                    <p>{walletFundUsed}</p>
                                </div>

                                <div className="o-flex total-or">
                                    <h5>Total:</h5>
                                    <h5>{jobPrice.toFixed(2)}</h5>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <PromoCodeModal showModal={showModal} setShowModal={setShowModal} jobPrice={jobPrice} handleJobPrice={handleJobPrice} />
            <ReedemRewardpointModal open={openReward} handleClose={handleRewardClose} handleAmmount={handleRewardAmmount} jobPrice={jobPrice} checkPrice={(user?.rewardPoint * (5/100)).toFixed(0)} />
            <ReedemWalletModal open={openWallet} handleClose={handleWalletClose} handleAmmount={handleWalletAmmount} jobPrice={jobPrice} checkPrice={user?.affiliatedAmount} />
        </>
    )
}

export default Payment