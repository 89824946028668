import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  startLoading,
  stopLoading,
} from "../../../redux/reducers/loadingSlice";
import { setJobs } from "../../../redux/reducers/jobSlice";
import { getAllJobs } from "../../../services/api/jobApis";
import SingleJobItem from "./SingleJobItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";

const TranslatorManagerDashboard = () => {
  const dispatch = useDispatch();
  const { myJobs, totalJobCount, currentPage } = useSelector(
    (state) => state.job
  );
  const [sort, setSort] = useState({ sortBy: null, sortType: null });
  const [pages, setPages] = useState([]);
  const [search, setSearch] = useState('');
  const getTransManagerProjects = async (page, sortBy, sortType) => {
    if (sortBy && sortType) {
      setSort({
        sortBy: sortBy,
        sortType: sortType,
      });
    }
    dispatch(setJobs({ jobs: null, pageNumber: 1, totalJobCount: 0 }));
    dispatch(startLoading());
    let data =
      sortBy && sortType
        ? await getAllJobs("all", page ?? 1, sortBy, sortType, search)
        : await getAllJobs("all", page ?? 1, null, null, search);
    if (data?.data?.length > 0) {
      dispatch(
        setJobs({
          jobs: data?.data,
          pageNumber: data?.pageNumber,
          totalJobCount: data?.dataCount,
        })
      );
    }
    dispatch(stopLoading());
  };
  useEffect(() => {
    getTransManagerProjects();
    return () => {
      dispatch(setJobs({ jobs: null, pageNumber: 1, totalJobCount: 0 }));
    }
  }, []);
  useEffect(() => {
    setPages(Array(Math.ceil(totalJobCount / 10)).fill("page"));
  }, [myJobs]);
  const loadMoreData = (page) => {
    getTransManagerProjects(page, sort.sortBy, sort.sortType);
  };
  const sortButtonState = (sortBy, sortType) => {
    if (sortBy === sort.sortBy && sortType === sort.sortType) {
      return { color: "gray", pointerEvents: "none" };
    } else {
      return false;
    }
  };
  return (
    <section className="pm-sec-main sec_pad">
      <div className="container">
        <h2>Translation Project Manager</h2>
        <div className="pm-sec-wrap">
          <div className="signin-form-wrap manager-search-box">
            <form onSubmit={(e) => { e.preventDefault(); return getTransManagerProjects(currentPage, sort.sortBy, sort.sortType); }}>
              <div className="form-group password">
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  className="form-control"

                />

                <span className="forgot-password-eye-toggle ">
                  <FontAwesomeIcon icon={faSearch} onClick={(e) => getTransManagerProjects(currentPage, sort.sortBy, sort.sortType)} />
                </span>
              </div>
            </form>
          </div>
          <div className="table-pm-style-wrap">
            <div className="table-pm-head">
              <div className="ta-top">
                <p>#Order</p>
              </div>
              <div className="ta-top">
                <p className={`large-sort-section`}>
                  Wordcount
                  <span className={`sort-section`}>
                    <span
                      style={{
                        cursor: sortButtonState("wordCount", "asc")
                          ? "not-allowed"
                          : "",
                      }}
                    >
                      <FontAwesomeIcon
                        style={sortButtonState("wordCount", "asc")}
                        icon={faSortUp}
                        onClick={() =>
                          getTransManagerProjects(
                            currentPage ?? 1,
                            "wordCount",
                            "asc"
                          )
                        }
                      />
                    </span>
                    <span
                      style={{
                        cursor: sortButtonState("wordCount", "desc")
                          ? "not-allowed"
                          : "",
                      }}
                    >
                      <FontAwesomeIcon
                        style={sortButtonState("wordCount", "desc")}
                        icon={faSortDown}
                        onClick={() =>
                          getTransManagerProjects(
                            currentPage ?? 1,
                            "wordCount",
                            "desc"
                          )
                        }
                      />
                    </span>
                  </span>
                </p>
              </div>
              <div className="ta-top">
                <p className={`large-sort-section`}>
                  Deadline
                  <span className={`sort-section`}>
                    <span
                      style={{
                        cursor: sortButtonState("deadline", "asc")
                          ? "not-allowed"
                          : "",
                      }}
                    >
                      <FontAwesomeIcon
                        style={sortButtonState("deadline", "asc")}
                        icon={faSortUp}
                        onClick={() =>
                          getTransManagerProjects(
                            currentPage ?? 1,
                            "deadline",
                            "asc"
                          )
                        }
                      />
                    </span>
                    <span
                      style={{
                        cursor: sortButtonState("deadline", "desc")
                          ? "not-allowed"
                          : "",
                      }}
                    >
                      <FontAwesomeIcon
                        style={sortButtonState("deadline", "desc")}
                        icon={faSortDown}
                        onClick={() =>
                          getTransManagerProjects(
                            currentPage ?? 1,
                            "deadline",
                            "desc"
                          )
                        }
                      />
                    </span>
                  </span>
                </p>
              </div>
              <div className="ta-top">
                <p>QR-Code</p>
              </div>
              <div className="ta-top">
                <p>Language</p>
              </div>
              <div className="ta-top">
                <p className={`large-sort-section`}>
                  Milestone status
                  <span className={`sort-section`}>
                    <span
                      style={{
                        cursor: sortButtonState("jobStatus", "asc")
                          ? "not-allowed"
                          : "",
                      }}
                    >
                      <FontAwesomeIcon
                        style={sortButtonState("jobStatus", "asc")}
                        icon={faSortUp}
                        onClick={() =>
                          getTransManagerProjects(
                            currentPage ?? 1,
                            "jobStatus",
                            "asc"
                          )
                        }
                      />
                    </span>
                    <span
                      style={{
                        cursor: sortButtonState("jobStatus", "desc")
                          ? "not-allowed"
                          : "",
                      }}
                    >
                      <FontAwesomeIcon
                        style={sortButtonState("jobStatus", "desc")}
                        icon={faSortDown}
                        onClick={() =>
                          getTransManagerProjects(
                            currentPage ?? 1,
                            "jobStatus",
                            "desc"
                          )
                        }
                      />
                    </span>
                  </span>
                </p>
              </div>
              <div className="ta-top">
                <p className={`large-sort-section`}>
                  Price/word
                  <span className={`sort-section`}>
                    <span
                      style={{
                        cursor: sortButtonState("perWordPrice", "asc")
                          ? "not-allowed"
                          : "",
                      }}
                    >
                      <FontAwesomeIcon
                        style={sortButtonState("perWordPrice", "asc")}
                        icon={faSortUp}
                        onClick={() =>
                          getTransManagerProjects(
                            currentPage ?? 1,
                            "perWordPrice",
                            "asc"
                          )
                        }
                      />
                    </span>
                    <span
                      style={{
                        cursor: sortButtonState("perWordPrice", "desc")
                          ? "not-allowed"
                          : "",
                      }}
                    >
                      <FontAwesomeIcon
                        style={sortButtonState("perWordPrice", "desc")}
                        icon={faSortDown}
                        onClick={() =>
                          getTransManagerProjects(
                            currentPage ?? 1,
                            "perWordPrice",
                            "desc"
                          )
                        }
                      />
                    </span>
                  </span>
                </p>
              </div>
              <div className="ta-top">
                <p className={`large-sort-section`}>
                  Job status
                  <span className={`sort-section`}>
                    <span
                      style={{
                        cursor: sortButtonState("jobStatus", "asc")
                          ? "not-allowed"
                          : "",
                      }}
                    >
                      <FontAwesomeIcon
                        style={sortButtonState("jobStatus", "asc")}
                        icon={faSortUp}
                        onClick={() =>
                          getTransManagerProjects(
                            currentPage ?? 1,
                            "jobStatus",
                            "asc"
                          )
                        }
                      />
                    </span>
                    <span
                      style={{
                        cursor: sortButtonState("jobStatus", "desc")
                          ? "not-allowed"
                          : "",
                      }}
                    >
                      <FontAwesomeIcon
                        style={sortButtonState("jobStatus", "desc")}
                        icon={faSortDown}
                        onClick={() =>
                          getTransManagerProjects(
                            currentPage ?? 1,
                            "jobStatus",
                            "desc"
                          )
                        }
                      />
                    </span>
                  </span>
                </p>
              </div>
              <div className="ta-top">
                <p>Action</p>
              </div>
            </div>
            <div className="table-pm-body managers-table">
              {myJobs &&
                myJobs?.map((item, idx) => {
                  return <SingleJobItem item={item} index={idx} />;
                })}
            </div>
          </div>
        </div>
        <div className="page-n">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              {currentPage > 1 ? (
                <li
                  className="page-item"
                  onClick={() => loadMoreData(currentPage - 1)}
                >
                  <a className="page-link" href="#">
                    <i className="fa fa-angle-left" />
                  </a>
                </li>
              ) : (
                <li></li>
              )}
              {pages?.map((i, index) => {
                return (
                  <li
                    className={`page-item`}
                    onClick={() => loadMoreData(index + 1)}
                  >
                    <a
                      className={`page-link ${currentPage === index + 1 && "active-page"
                        }`}
                      href="#"
                    >
                      {index + 1}
                    </a>
                  </li>
                );
              })}
              {myJobs?.length > 9 ? (
                <li
                  className="page-item"
                  onClick={() => loadMoreData(currentPage + 1)}
                >
                  <a className="page-link" href="#">
                    <i className="fa fa-angle-right" />
                  </a>
                </li>
              ) : (
                <li></li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default TranslatorManagerDashboard;
