import React, { useRef } from "react";

const QRCodeComponent = ({ qrLink }) => {
  const imgRef = useRef(null);

  // Function to copy the QR code image to clipboard
  const copyQRCodeAsImage = () => {
    const svg = new Blob([qrLink], { type: "image/svg+xml" });
    const url = URL.createObjectURL(svg);

    // Create an image element and load the SVG as its source
    const img = new Image();
    img.src = url;

    img.onload = () => {
      // Create a canvas element to convert the SVG to PNG
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      // Convert the canvas to a PNG data URL
      canvas.toBlob((blob) => {
        // Copy the PNG blob to the clipboard
        const item = new ClipboardItem({ "image/png": blob });
        navigator.clipboard.write([item]).then(() => {
          alert("QR code image copied to clipboard!");
        });
      });
    };
  };

  return (
    <div>
      {/* Render the QR code SVG */}
      {/* <div dangerouslySetInnerHTML={{ __html: qrLink }} ref={imgRef} /> */}
      {/* Button to copy the QR code as an image */}
      <button onClick={copyQRCodeAsImage}>
        QR Code
      </button>
    </div>
  );
};

export default QRCodeComponent;
