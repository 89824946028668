import { useState } from "react";
import { NavLink, redirect } from "react-router-dom";
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { userSignUp } from "../../../services/api/authApis";
import { setSession } from "../../../services/api/authApis";
import { useDispatch } from "react-redux";
import { useFormik } from 'formik'
import { signupBusinessFromValidatorUser } from '../../../services/formValidator/authValidator'
import { signUpFormCall } from '../../../services/api/authApis'
import SocialLogin from '../../../components/common/socialLogin/SocialLoginSignup'

const Business = ({ slectedUserRole, termCondition, settermsCondition, setSelectedtUserRole, findCode, setIsBusiness }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const [businessTermCondition, setBusinessTermCondition] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const signUpFormik = useFormik({
    initialValues: signupBusinessFromValidatorUser.initialValues,
    validationSchema: signupBusinessFromValidatorUser.validationSchema,
    onSubmit: (values) => {

      let data = values
      if (termCondition && businessTermCondition) {
        if (sessionStorage.getItem('userType') && localStorage.getItem('reDirect_url')) {
          data.userType = sessionStorage.getItem('userType')
        }

        signUpFormCall(data, navigate, slectedUserRole, signUpFormik, dispatch, findCode)
      }
      else {
        toast.warning('Please select terms and conditions before signup')
      }
    }
  })
  const passwordHandler = () => {
    setShowPassword((prev) => !prev)
  }
  const confirmPasswordHandler = () => {
    setShowConfirmPassword((prev) => !prev)
  }

  return (
    <div className="tab-pane fade  active show">
      <div className="signin-form-wrap">
        <form onSubmit={signUpFormik.handleSubmit}>
          <h4>Sign up as Business</h4>
          <p>Please enter your actual email.</p>

          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label>Name</label>
                <input type="text" placeholder="" className="form-control" id='name' name='name' onChange={signUpFormik.handleChange} value={signUpFormik.values.name} />
                <p className='form-error-message'>{signUpFormik.touched.name && signUpFormik.errors.name}</p>

              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>Email</label>
                <input type="email" placeholder="" className="form-control" id='email' name='email' onChange={signUpFormik.handleChange} value={signUpFormik.values.email} />
                <p className='form-error-message'>{signUpFormik.touched.email && signUpFormik.errors.email}</p>

              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group password">
                <label>Password</label>
                <input type={showPassword ? "text" : "password"} placeholder="" className="form-control" id='password' name='password' onChange={signUpFormik.handleChange} value={signUpFormik.values.password} />

                <p className='form-error-message'>{signUpFormik.touched.password && signUpFormik.errors.password}</p>
                <span onClick={passwordHandler} className="forgot-password-eye-toggle "> <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} /></span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group password">
                <label>Confirm password</label>
                <input type={showConfirmPassword ? "text" : "password"} placeholder="" className="form-control" id='confirmPassword' name='confirmPassword' onChange={signUpFormik.handleChange} value={signUpFormik.values.confirmPassword} />
                <p className='form-error-message'>{signUpFormik.touched.confirmPassword && signUpFormik.errors.confirmPassword}</p>
                <span onClick={confirmPasswordHandler} className="forgot-password-eye-toggle "> <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} /></span>

              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group custom-checkbox">
                <input type="checkbox" id="createaccountbusiness" name="createaccountbusiness" checked={businessTermCondition} onChange={(e) => { setBusinessTermCondition(e.target.checked) }} />
                <label htmlFor="createaccountbusiness">By creating an account, you acknowledge that you have read, understand, and agree to Lingvara's <NavLink to="/business/terms-conditions">Business Service Agreement</NavLink></label>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <button type="submit" className="btn w-100">Sign Up</button>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="or-divider text-center">
                <span>Or</span>
              </div>
              <SocialLogin type='signup' role='user' />
              <div className="form-group mb-0 text-center">
                <p>Already have and account? <a style={{ color: "blue" }} onClick={() => navigate('/login')}>Sign In</a></p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Business;





