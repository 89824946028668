import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setJobPrice, setPromoCode } from '../../../redux/reducers/jobPostSlice'
import { getPromoCode } from '../../../services/api/jobApis'
import { toast } from 'react-toastify'

const PromoCodeModal = ({ setShowModal, showModal, jobPrice, handleJobPrice }) => {
    const dispatch = useDispatch()
    const { promoCode } = useSelector(state => state.jobPost)

    const [promoList, setPromoList] = useState()
    const [promo, setPromo] = useState(promoCode)

    const handlePromoCode = (value) => {
        setPromo(value)
        handleJobPrice()
    }

    const handlePromo = () => {
        if (promo?.discountType === 'percentage' ? (jobPrice < (jobPrice * (promo?.discountAmount / 100))) : (jobPrice < promo?.discountAmount)) {
            toast.error('You can not use this promocode')
        }
        else {
            dispatch(setPromoCode({ promoCode: { ...promo, discountedAmmount: promo?.discountType === 'percentage' ? ((jobPrice * (promo?.discountAmount / 100))) : (promo?.discountAmount) } }))
            dispatch(setJobPrice({
                jobPrice: promo?.discountType === 'percentage' ? (jobPrice - (jobPrice * (promo?.discountAmount / 100))) : (jobPrice - promo?.discountAmount)
            }))
            setShowModal(false)
        }
    }
    const closeModal = () => {
        handleJobPrice()
        // dispatch(setPromoCode({ promoCode: "" }))
        setShowModal(false)
        setPromo(promoCode)
    }

    const getPromo = async () => {
        let data = await getPromoCode()
        if (data?.result?.data) {
            setPromoList(data?.result?.data)
        }
    }
    useEffect(() => {
        getPromo()
    }, [])
    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            onHide={closeModal}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Promo Codes
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table promo-table">
                    <thead>
                        <tr>
                            <th scope="col">Promo</th>
                            <th scope="col">Percentage / Fixed Rate</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {promoList && promoList?.map((item) => {
                            return (
                                <tr>
                                    <td>{item?.promoCode}</td>
                                    <td>{item?.discountType === 'percentage' ? `${item?.discountAmount}%` : `${item?.discountAmount}$`}</td>
                                    <td><button disabled={(promo?.promoCode === item?.promoCode)} className='btn' onClick={() => handlePromoCode(item)}>{(promo?.promoCode === item?.promoCode) ? 'Applied' : 'Apply'}</button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={promo?.promoCode === promoCode?.promoCode} onClick={handlePromo}>Done</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PromoCodeModal