import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobs: null,
  acceptedJobs: null,
  myJobs: null,
  myReceipts: null,
  currentPage: 1,
  totalJobCount: 0,
  selectedJobDetails: null,
  selectedReceiptDetails: null,
  assignUserList: null,
  managersList: null,
  ratingReview: null,
  pageNumberReceipts: 1,
  dataPerPageReceipts: 10,
  totalReceiptCount: 0,
  checkMultipleFiles: false,
  uploadedFiles: [],
};

export const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    setJobs: (state, actions) => {
      const { jobs, pageNumber, totalJobCount } = actions.payload;
      if (state.myJobs?.length > 0 && jobs) {
        state.myJobs = [...state.myJobs, ...jobs];
      } else {
        state.myJobs = jobs;
      }
      state.currentPage = pageNumber;
      state.totalJobCount = totalJobCount;
    },
    setSelectedJobDetails: (state, actions) => {
      const { selectedJob } = actions.payload;
      state.selectedJobDetails = selectedJob;
    },
    setSelectedReceiptDetails: (state, actions) => {
      const { selectedReceipt } = actions.payload;
      state.selectedReceiptDetails = selectedReceipt;
    },
    setAssignUserList: (state, actions) => {
      const { users } = actions.payload;
      if (state.assignUserList?.length > 0 && users) {
        state.assignUserList = [...state.assignUserList, ...users];
      } else {
        state.assignUserList = users;
      }
    },
    setManagersList: (state, actions) => {
      const { users } = actions.payload;
      if (state.managersList?.length > 0 && users) {
        state.managersList = [...state.managersList, ...users];
      } else {
        state.managersList = users;
      }
    },
    setReviewRating: (state, actions) => {
      const { reviewRating } = actions.payload;
      state.ratingReview = reviewRating;
    },
    setMyReceipts: (state, actions) => {
      const { receipts, pageNumber, dataPerPage, totalReceiptCount } =
        actions.payload;
      state.myReceipts = receipts;
      state.pageNumberReceipts = pageNumber;
      state.dataPerPageReceipts = dataPerPage;
      state.totalReceiptCount = totalReceiptCount;
    },
    setCheckMultipleFiles: (state, actions) => {
      const { checkMultipleFiles } = actions.payload;
      state.checkMultipleFiles = checkMultipleFiles;
    },
    setUploadedFiles: (state, actions) => {
      const { uploadedFiles } = actions.payload;
      state.uploadedFiles = uploadedFiles;
    },
    clearJobs: (state, actions) => {
      state.jobs = null;
      state.acceptedJobs = null;
      state.myJobs = null;
      state.currentPage = 1;
      state.totalJobCount = 0;
      state.selectedJobDetails = null;
      state.assignUserList = null;
      state.managersList = null;
      state.selectedReceiptDetails = null;
      state.myReceipts = null;
      state.pageNumberReceipts = 1;
      state.dataPerPageReceipts = 10;
      state.totalReceiptCount = 0;
      state.checkMultipleFiles = false;
      state.uploadedFiles = [];
    },
  },
});

// this is for dispatch
export const {
  setJobs,
  setSelectedJobDetails,
  setAssignUserList,
  setReviewRating,
  setManagersList,
  setSelectedReceiptDetails,
  setMyReceipts,
  clearJobs,
  setCheckMultipleFiles,
  setUploadedFiles,
} = jobSlice.actions;

// this is for configureStore
export default jobSlice.reducer;
