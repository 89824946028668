import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTimeAgo } from '../../../utils/getTimeAgoTimer';
import { timeAgoFunc } from '../../../utils/timeAgoFunc';
import ReviewModal from './ReviewModal';
import { useNavigate } from 'react-router-dom';
import { chatAccess } from '../../../services/api/chatRoomApis';
import { getChatRoomMessage, joinChat, sendNotification } from '../../../services/socket';
import { setActiveroom } from '../../../redux/reducers/chatRoomSlice';
import { acceptRejectRecheckJobPost, getReview, getSingleJob } from '../../../services/api/jobApis';
import { setReviewRating, setSelectedJobDetails } from '../../../redux/reducers/jobSlice';
import ReviewRating from '../../../components/common/review/ReviewRating';
import { startLoading, stopLoading } from '../../../redux/reducers/loadingSlice';
import RecheckCommentModal from './RecheckCommentModal';
import FileAClaimCommentModal from './FileAClaimCommentModal';

const DetailsTranslation = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { selectedJobDetails } = useSelector((state) => state.job)
    const { user } = useSelector((state) => state.auth)
    const { ratingReview } = useSelector((state) => state.job);

    const [timeAgo, setTimeAgo] = useState("");
    const [showReviewModal, setShowReviewModal] = useState(false)
    const [showRecheckCommentModel, setShowRecheckCommentModel] = useState(false)
    const [showFileAClaimCommentModal, setShowFileAClaimCommentModal] = useState(false)

    useEffect(() => {
        const intervalId = setInterval(() => {
            const timeAgo = getTimeAgo(selectedJobDetails?.jobStatusForPM == 'delivered' || selectedJobDetails?.jobStatusByUser != 'none' ? 0 : selectedJobDetails?.deadline);
            setTimeAgo(timeAgo);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [selectedJobDetails?.deadline]);

    useEffect(() => {
        (async () => {
            if (selectedJobDetails?._id) {
                let jobReviewData = await getReview(selectedJobDetails?._id)
                if (jobReviewData?.serverResponse?.code === 200) {
                    dispatch(setReviewRating({ reviewRating: jobReviewData.result?.data }))

                }
            }

        })()
    }, [selectedJobDetails?._id])

    const gotoChat = async () => {
        let accessData = await chatAccess(selectedJobDetails?.assignedProjectManagerId)
        if (accessData?.serverResponse?.code === 200) {
            joinChat([accessData?.result[0]?._id, user?._id, selectedJobDetails?.assignedProjectManagerId])
            dispatch(setActiveroom({ activeRoom: accessData?.result[0] })) // will put this to socket event when roomid and userid will be send in an object
            getChatRoomMessage({ userId: user?._id, chatRoomId: accessData?.result[0]?._id, pageNumber: 1, dataPerPage: 10 })
        }
        navigate('/customer-chat')
    }
    const aaceptRecheckRejectJob = async (e, status) => {
        e.preventDefault()
        if (status === "recheck") {
            setShowRecheckCommentModel(true)
        }else if(status === "reject"){
            setShowFileAClaimCommentModal(true)
        }
        else {
            let acceptRejectRecheckJobData = await acceptRejectRecheckJobPost({ jobId: selectedJobDetails?._id, status: status })
            if (acceptRejectRecheckJobData?.serverResponse?.code === 200) {
                notificationHandler(status)
            }
        }





    }

    const gettingSingleJobDetails = async () => {
        dispatch(startLoading())
        let { data } = await getSingleJob(selectedJobDetails?._id)
        if (data) {
            dispatch(setSelectedJobDetails({ selectedJob: data }))
        }
        dispatch(stopLoading())
    }
    const notificationHandler = (status) => {
        gettingSingleJobDetails()
        let body = {
            jobId: selectedJobDetails?._id,
            senderId: user?._id,
            receiverId: selectedJobDetails?.assignedProjectManagerId,
            notificationMessage: `${selectedJobDetails?.jobTitle} job is ${status} by ${user?.name}`,
        }
        sendNotification(body)
    }

    return (
        <div className="user-dashbord">
            <div className="user-dtls-jb-pst-page common-padd">
                <div className="container">
                    <div className="user-dtls-jb-pst-wraper">
                        <div className="user-dtls-jb-pst-top">
                            <h4>
                                {selectedJobDetails?.jobTitle}
                                <span className="chat" onClick={gotoChat}>
                                    <p>
                                        <img src="/images/chat.svg" alt="" />
                                    </p>
                                </span>
                            </h4>
                            <ul className="tags-sec">
                                <li>
                                    <span>
                                        <img src="/images/icon51.svg" />
                                    </span>{" "}
                                    Translation
                                </li>
                                <li className="light-blue-db">
                                    <span>
                                        <img src="/images/icon52.svg" />
                                    </span>{" "}
                                    {timeAgoFunc(selectedJobDetails?.createdAt)}
                                </li>
                                <li className="light-green-db">
                                    <span>
                                        <img src="/images/icon53.svg" />
                                    </span>{" "}
                                    {selectedJobDetails?.chooseTranslator}
                                </li>
                            </ul>
                            <h5>Project Overview:</h5>
                            <p>
                                {selectedJobDetails?.comment}
                            </p>
                        </div>
                        <div className="user-dtls-jb-pst-mdl common-btm-mrgn">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4">
                                            <div className="sngl-box">
                                                <h6>Order number:</h6>
                                                <p>{selectedJobDetails?.orderNumber}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                            <div className="sngl-box">
                                                <h6>Document type:</h6>
                                                <p>{selectedJobDetails?.jobDocumentType}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                            <div className="sngl-box">
                                                <h6>Language:</h6>
                                                <p>{selectedJobDetails?.translateFrom} to {selectedJobDetails?.translateTo}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                            <div className="sngl-box">
                                                <h6>Word count:</h6>
                                                <p>{selectedJobDetails?.wordCount}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4">
                                    <div className="sngl-box">
                                        <h6>Deadline:</h6>
                                        <p>{timeAgo}</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4">
                                    <div className="sngl-box">
                                        <h6>Additional services:</h6>
                                        <p>{selectedJobDetails?.chooseTranslator ===
                                            "Subject Specialized Translator" ? "Specialized" : "None"}</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4">
                                    <div className="sngl-box">
                                        <h5>Job status:</h5>
                                        <div className="progress-bar-sec">
                                            <div className="progress" role="progressbar" aria-label="Success example" aria-valuenow={selectedJobDetails?.jobStatus} aria-valuemin={0} aria-valuemax={100}>
                                                <div className="progress-bar bg-success" style={{ width: `${selectedJobDetails?.jobStatus}%` }}><span>{selectedJobDetails?.jobStatus}% </span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={`download-job`}>
                            <div className="user-dtls-jb-pst-end">
                                <h5>Job file:</h5>
                                <div className="row">
                                    <div className="">
                                        <div className="download-file-box">
                                            <a href={selectedJobDetails?.jobFile?.location} download>
                                                <span>
                                                    <img src="/images/icon55.svg" alt="" />
                                                </span>
                                                {selectedJobDetails?.jobFile?.name}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(selectedJobDetails?.isCompleted || selectedJobDetails?.jobStatusByUser !== "none") && (selectedJobDetails?.jobCompletedFile && <div className="user-dtls-jb-pst-end">
                                <h5>Download file:</h5>
                                <div className="row">
                                    <div className="">
                                        <div className="download-file-box">
                                            <a href={selectedJobDetails?.jobCompletedFileMain ? selectedJobDetails?.jobCompletedFileMain?.location : selectedJobDetails?.jobCompletedFile?.location} download>
                                                <span>
                                                    <img src="/images/icon55.svg" alt="" />
                                                </span>
                                                {selectedJobDetails?.jobCompletedFileMain ? selectedJobDetails?.jobCompletedFileMain?.name : selectedJobDetails?.jobCompletedFile?.name}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        </div>

                        <div className="row">
                            <div className='accept-reject-buttons'>

                                {((selectedJobDetails?.isCompleted && selectedJobDetails?.jobStatusByUser === "none") || (selectedJobDetails?.isCompleted && selectedJobDetails?.jobStatusByUser === "correction")) && <button className='btn btn-border-g' onClick={(e) => aaceptRecheckRejectJob(e, "accept")}>Accept</button>}


                                {(selectedJobDetails?.isCompleted && selectedJobDetails?.jobStatusByUser === "none" && selectedJobDetails?.correctionCount === 0) && <button className='btn btn-border-g' onClick={(e) => aaceptRecheckRejectJob(e, "recheck")}>Ask for corrections</button>}


                                {((selectedJobDetails?.isCompleted && selectedJobDetails?.jobStatusByUser === "none" && selectedJobDetails?.correctionCount === 1) || (selectedJobDetails?.isCompleted && selectedJobDetails?.jobStatusByUser === "correction")) && <button className='btn btn-border-g' onClick={(e) => aaceptRecheckRejectJob(e, "reject")}>File a Claim with the Project Manager</button>}
                            </div>


                        </div>
                        {(selectedJobDetails?.isCompleted && selectedJobDetails?.certificateLink) && <div className={`write-review-btn`}>
                            <a
                                href={selectedJobDetails?.certificateLink}
                                className="btn btn-border-g"
                                download
                            >
                                <img
style={{marginRight:"5px"}}
                                    src="/images/Vector.svg"
                                    alt="Certificate"
                                    width="15" // Adjust the width as needed
                                    height="15" // Adjust the height as needed
                                />
                                Download certificate
                            </a>
                        </div>}
                        {selectedJobDetails?.isCompleted && !(ratingReview && ratingReview?._id) && <div className={`write-review-btn`}>
                            <button
                                onClick={() => setShowReviewModal(true)}
                                className="btn btn-border-g"
                            >
                                Write a review
                            </button>
                        </div>}

                    </div>
                </div>
            </div>
            <ReviewModal jobId={selectedJobDetails?._id} setShowReviewModal={setShowReviewModal} showReviewModal={showReviewModal} />
            {ratingReview && ratingReview?._id && <ReviewRating ratingReview={ratingReview} setShowReviewModal={setShowReviewModal} />}
            {showRecheckCommentModel && <RecheckCommentModal notificationHandler={notificationHandler} selectedJobDetailsId={selectedJobDetails?._id} showRecheckCommentModel={showRecheckCommentModel} setShowRecheckCommentModel={setShowRecheckCommentModel} />}
            {showFileAClaimCommentModal && <FileAClaimCommentModal selectedJobDetailsId={selectedJobDetails?._id} showFileAClaimCommentModal={showFileAClaimCommentModal} setShowFileAClaimCommentModal={setShowFileAClaimCommentModal} />}
        </div>


    )
}

export default DetailsTranslation