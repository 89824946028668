import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader, Button } from 'react-bootstrap'
import { acceptRejectRecheckJobPost } from '../../../services/api/jobApis'

const FileAClaimCommentModals = ({ selectedJobDetailsId, showFileAClaimCommentModal, setShowFileAClaimCommentModal }) => {

    const addCommentFunc = async () => {
        let acceptRejectRecheckJobData = await acceptRejectRecheckJobPost({ jobId: selectedJobDetailsId, status: "reject"})
        if (acceptRejectRecheckJobData?.serverResponse?.code === 200 || acceptRejectRecheckJobData?.serverResponse?.code === 201) {
            setShowFileAClaimCommentModal(false)
        }
    }

    return (
        <Modal
            show={showFileAClaimCommentModal}
            onHide={() => setShowFileAClaimCommentModal(false)}
            centered
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <p>The project manager will contact you ASAP via your registered email or phone number.</p>
                <Button variant="primary" onClick={addCommentFunc}>Ok</Button>


            </Modal.Body>
        </Modal>
    )
}

export default FileAClaimCommentModals
