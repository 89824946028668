import React, { useEffect, useState } from "react";
import { getTimeAgo } from "../../../utils/getTimeAgoTimer";
import { timeAgoFunc } from "../../../utils/timeAgoFunc";
import { setSelectedJobDetails } from "../../../redux/reducers/jobSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { applyJob } from "../../../services/api/jobApis";
import { setJobs } from "../../../redux/reducers/jobSlice";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SingleJobCard = ({ item, idx, getOngoingProjects }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentPage } = useSelector((state) => state.job);
  const [timeAgo, setTimeAgo] = useState("");
  useEffect(() => {
    const intervalId = setInterval(() => {
      const timeAgo = getTimeAgo(item?.jobStatusForPM == 'delivered' || item?.jobStatusByUser != 'none' ? 0 : item?.deadline);
      setTimeAgo(timeAgo);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [item?.deadline]);
  const gotoSelectedProject = (data) => {
    dispatch(setSelectedJobDetails({ selectedJob: data }));
    navigate(`/proofreader-jobs/job-details`, { state: { id: data._id } });
  };
  const applyForJob = async (jobId) => {
    const apiData = {
      jobId: jobId,
      isAccepted: true,
    };
    let data = await applyJob(apiData);
    if (data?.serverResponse?.code === 201) {
      dispatch(setJobs({ jobs: null, pageNumber: 0, totalJobCount: 0 }));
      getOngoingProjects(currentPage);
    }
  };
  return (
    <div className="col-lg-6" key={idx}>
      <div className="project-with-progress-box">

        {item?.translatorSpecialisation !== "" && (
          <div className="site-tag">Specialized</div>
        )}
        <h5 style={{ cursor: "pointer" }} onClick={() => gotoSelectedProject(item)}>{item?.jobTitle}(<span>
          <FontAwesomeIcon size="xs" icon={faEye} />
        </span>)</h5>
        <ul className="tags-sec">
          <li className="light-blue-db">
            <span>
              <img src="/images/icon52.svg" />
            </span>{" "}
            {timeAgoFunc(item?.createdAt)}
          </li>
          <li className="light-green-db">
            <span>
              <img src="/images/gn-ic.svg" />
            </span>{" "}
            {item?.proofReadingService}
          </li>
          <li className="light-blue-db">
            <span>
              <img src="/images/icon62.svg" />
            </span>{" "}
            {item?.wordCount}
          </li>
          <li className="light-green-db">
            <span>
              <img src="/images/icon61.svg" />
            </span>{" "}
            {item?.perWordPrice}/word
          </li>
          <li className="light-blue-db">
            <span>
              <img src="/images/icon60.svg" />
            </span>{" "}
            {timeAgo}
          </li>
        </ul>
        <h6>Project Overview:</h6>
        <p>{item?.comment}</p>
        <div className="listing-area">
          <ul className="listing-sec">
            <li>
              <strong>Document type:</strong> {item?.jobDocumentType}
            </li>
            <li>
              <strong>Language:</strong> {item?.requiredEnglishDialect}
            </li>
            <li>
              <strong>Status:</strong> <span>{item?.jobStatus ?? 0}%</span>
            </li>
          </ul>
          <div className="sidt-btn">
            <ul>
              <li>
                <a
                  href={item?.jobFile?.location}
                  download
                  className="btn btn-border"
                >
                  Download file
                </a>
              </li>
              {!item?.isAssignedJob && (
                <li>
                  <span onClick={() => applyForJob(item?._id)} className="btn">
                    Apply
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: "70%" }}
            aria-valuenow={70}
            aria-valuemin={0}
            aria-valuemax={100}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleJobCard;
