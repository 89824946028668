import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { removeFileFromS3, checkWordCount, uploadJobFile } from '../../../services/api/jobPostApis'
import { useDispatch, useSelector } from 'react-redux'
import { clearjobPost, setActiveStep, updateBlobFile, updateJob, updateJobPostFileWordCount, updateJobPostFiles } from '../../../redux/reducers/jobPostSlice'
import DragDropUploadButton from '../../../components/common/DragDropUploadButton'
import { useFormik } from 'formik'
import { uploadJobFileFormValidator } from '../../../services/formValidator/jobValidator'

import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import ResponsiveStepper from './ResponsiveStepper'
import { getProfile } from '../../../services/api/profileApi'
import { updateUser } from '../../../redux/reducers/authSlice'
const UploadFile = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { jobWordCount, uploadFiles, blobFile, fileDetails } = useSelector((state) => state.jobPost)
    useEffect(() => {
        (async () => {
            if (blobFile && !uploadFiles) {
                wordCountCheck(blobFile)
            }
        })()
    }, [])
    function blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }
    const wordCountCheck = async (data) => {
        let uploadFileData = await checkWordCount(data[0])
        if (uploadFileData?.result) {


            dispatch(updateJobPostFileWordCount({ count: uploadFileData?.result?.data?.wordCount }))
            blobToBase64(data[0])
                .then(base64String => {
                    dispatch(updateBlobFile({ blobFile: { b64: base64String, name: data[0]?.path } }))
                })
                .catch(error => {
                    console.error('Error converting blob to base64:', error);
                });


        }
    }
    const removeItem = async () => {
        dispatch(updateBlobFile({ blobFile: null }))
    }
    // this use effect handle active route
    useEffect(() => {
        dispatch(setActiveStep({ activeStep: 'uploadFile' }))
    }, [])
    const gotoNextPage = () => {
        if (jobWordCount) {
            if (jobWordCount >= 50001) {
                navigate("/contact-us")
            }
            else {
                uploadFileNameDescFormik.handleSubmit()

            }
        }
        else {
            toast.error("Please upload the file")
        }
    }

    const uploadFileNameDescFormik = useFormik({
        initialValues: uploadJobFileFormValidator(fileDetails, 'initialValues'),
        validationSchema: uploadJobFileFormValidator(fileDetails, 'validationSchema'),
        onSubmit: (values) => {
            dispatch(updateJob({
                type: 'UPDATE_FILE_DETAILS',
                value: values
            }))
            dispatch(setActiveStep({ activeStep: 'typeOfDoc' }))
            navigate('/job-post/document-type')
        }
    })
    const getUserDetails = async () => {
        let resultData = await getProfile()
        dispatch(updateUser({ user: resultData?.result?.data }));
    }

    useEffect(() => {
        getUserDetails()
    }, [])
    const cancelThedata = () => {
        dispatch(clearjobPost())
        navigate('/customer-dashboard')
    }
    return (
        <>
            <ResponsiveStepper goNext={gotoNextPage} />
            <div className="card2 show">
                {blobFile ?
                    <div className="file-list-table">
                        <div className="signin-form-wrap e-ch upload-file-section">
                            <div className="form-group">
                                <label>Document Name</label>
                                <input type="text" placeholder="Enter the name of the document that should appear on the certificate" id='name' name='name' onChange={uploadFileNameDescFormik.handleChange} value={uploadFileNameDescFormik?.values?.name} className="form-control" />
                                <p className='form-error-message'>{uploadFileNameDescFormik.touched.name &&
                                    uploadFileNameDescFormik.errors.name}</p>
                            </div>
                            {/* <div className="form-group">
                                <label htmlFor="">Description</label>
                                <textarea name="description" id="description" onChange={uploadFileNameDescFormik.handleChange} value={uploadFileNameDescFormik?.values?.description} cols="10" rows="7" className="form-control"></textarea>
                                <p className='form-error-message'>{uploadFileNameDescFormik.touched.description &&
                                    uploadFileNameDescFormik.errors.description}</p>
                            </div> */}
                            <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th colSpan="2">File name</th>
                                        <th scope="col">Word Count</th>
                                        <th scope="col"></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan="2">
                                            <h6><span><i className="fas fa-file-alt"></i></span>{blobFile?.name}</h6>
                                        </td>
                                        <td>{jobWordCount}</td>
                                        <td><p onClick={removeItem}><FontAwesomeIcon icon={faTrashAlt} /></p></td>

                                    </tr>

                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="profile-form-box" style={{ position: 'relative' }}>
                        <div className="zone">
                            <div id="dropZ">
                                <img src="/images/file.png" alt="" />
                                <div className="drag-text">Drag & drop your entire document here.</div>
                                <div className="drag-text">Supported file types: 
                                Images; Documents (.docx); Spreadsheets (.xlxs , .csv); PDF; Powerpoint (.pptx)</div>
                                <div className="pro-or mt-4">
                                    <span> OR</span>
                                </div>
                                <DragDropUploadButton btnCss={"upload-f"} btnName={"Browse File"} multiple={false} disabled={!!blobFile} />
                                <DragDropUploadButton btnName={""} multiple={false} onChange={wordCountCheck} disabled={!!blobFile} />

                            </div>

                        </div>

                    </div>
                }

                <div className="button-set">
                    <div onClick={cancelThedata} className="Cancel-button text-center mt-1 ml-2 btn btn-border-black">Cancel
                    </div>
                    <div onClick={gotoNextPage} className="next-button text-center mt-1 ml-2 btn">Next
                    </div>
                </div>

            </div>

        </>
    )
}

export default UploadFile