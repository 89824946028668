import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { specificServiceProofreaderJson, citationStyleJson, proffReaderEngilshDialectJson, proffReaderWritingStyleJson } from '../../../constants/config'
import { setJobPrice, setTotalInitial, updateJob, setRewardPoint, clearjobPost, updateBlobFile, updateJobPostFiles } from '../../../redux/reducers/jobPostSlice'
import { jobPostProofReaderVerification } from '../../../services/formValidator/jobValidator'
import { useNavigate } from 'react-router-dom'
import ResponsiveStepper from './ResponsiveStepper'
import { removeFileFromS3, uploadJobFile, uploadStyleSheetFile, createJob } from '../../../services/api/jobPostApis'
import { useEffect, useState } from 'react'
import { startLoading, stopLoading } from '../../../redux/reducers/loadingSlice'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import DragDropUploadButton from '../../../components/common/DragDropUploadButton'
import { t } from 'i18next';

const ProofReaderConfigure = () => {
    const dispatch = useDispatch()
    const [newCitationStyleJson, setCitationStyleJson] = useState(citationStyleJson)
    const navigate = useNavigate()
    const {
        jobWordCount,
        uploadFiles,
        fileDetails,
        blobFile,
        documentType,
        serviceType,
        deadlineTime,
        configTranslation,
        configProofreading,
        perWordPrice,
        jobPrice,
        cartCurrency,
        totalInitial,
        multipliedBy,
        fixedRate,
        styleSheetFile,
        selectedSpeedOption
    } = useSelector((state) => state.jobPost);
    let jobPostApi = {
        jobWordCount,
        uploadFiles,
        fileDetails,
        blobFile,
        documentType,
        serviceType,
        deadlineTime,
        configTranslation,
        configProofreading,
        perWordPrice,
        jobPrice,
        cartCurrency,
        totalInitial,
        multipliedBy,
        fixedRate,
        styleSheetFile
    }
    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const [stylseSheetFile, setStyleSheetFile] = useState(null);
    const { user } = useSelector((state) => state.auth)
    const [useReward, setUseReward] = useState(false)
    const jobPostProofReaderFormik = useFormik({
        initialValues: jobPostProofReaderVerification.initialValues,
        validationSchema: jobPostProofReaderVerification.validationSchema,
        onSubmit: async (values) => {
            console.log(values, "jobPostProofReaderFormikValues")
            dispatch(updateJob({
                type: 'UPDATE_PROOFREADING_CONFIGURATION',
                value: { ...values }
            }))
            // let bodyData = {
            //     cart_amount: jobPrice
            // }
            // let payment = await createPayment(bodyData)
            // if (payment) {
            //     sessionStorage.setItem('transRef', payment?.result?.data?.tran_ref)
            //     window.location.href = payment?.result?.data?.redirect_url;
            // }
            if (user.role === "company") {
                dispatch(startLoading());
                let uploadFileData = await uploadJobFile(dataURLtoFile(blobFile?.b64, blobFile?.name))
                if (uploadFileData?.result) {
                    dispatch(updateJobPostFiles({ file: uploadFileData?.result.data }))
                    jobPostApi = {
                        ...jobPostApi,
                        configProofreading:values,
                        uploadFiles: uploadFileData?.result.data
                    }
                    let data = await createJob(jobPostApi, dispatch)
                    if (data?.serverResponse.code === 201) {
                        toast.success(data?.serverResponse.message)
                        dispatch(clearjobPost());
                        dispatch(updateBlobFile({ blobFile: null }))
                        navigate('/business-dashboard');
                    }
                    else {
                        dispatch(stopLoading());
                        toast.error(data?.serverResponse.message)
                    }
                }
            } else {
                navigate('/job-post/payment');
            }
        }
    })

    useEffect(() => {
        if (user.role != "company") {
            if (jobPrice > user?.rewardPoint && useReward) {
                if (jobPostProofReaderFormik.values.specService === 'Proofreading + Stylesheet Adherence') {
                    dispatch(setJobPrice({
                        jobPrice: Number((Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4))) - user?.rewardPoint).toFixed(4))
                    }))

                }
                else {
                    dispatch(setJobPrice({
                        jobPrice: Number((Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy))).toFixed(4))) - user?.rewardPoint).toFixed(4))
                    }))
                }
                dispatch(setRewardPoint({ rewardPointUsed: Number(user?.rewardPoint) }))
            }
            else if (!useReward) {
                if (jobPostProofReaderFormik.values.specService === 'Proofreading + Stylesheet Adherence') {
                    dispatch(setJobPrice({
                        jobPrice: Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4)))
                    }))
                }
                else {
                    dispatch(setJobPrice({
                        jobPrice: Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy))).toFixed(4)))
                    }))
                }
                dispatch(setRewardPoint({ rewardPointUsed: 0 }))
                return (() => {
                    dispatch(setTotalInitial({ total: jobPrice }))
                })
            }
        }

    }, [jobPostProofReaderFormik, useReward])

    const handleReward = () => {
        if (user?.rewardPoint < 1) {
            toast.warning('You can not use reward points until you have 1USD')
        }
        else {
            setUseReward(!useReward)
        }
    }
    useEffect(() => {
        if (jobPostProofReaderFormik.values.specService === 'Proofreading + Stylesheet Adherence') {
            setCitationStyleJson([
                {
                    name: "A. APA",
                    value: "APA",
                },
                {
                    name: "B. MLA",
                    value: "MLA",
                },
                {
                    name: "C. Chicago",
                    value: "Chicago",
                },
                {
                    name: "D. Harvard",
                    value: "Harvard",
                },
                {
                    name: "E. No particular preference",
                    value: "No particular preference",
                },
                {
                    name: "F. “I uploaded a stylesheet“",
                    value: "I uploaded a stylesheet",
                },

            ])
            jobPostProofReaderFormik.setFieldValue("citationstyle", "")
        }
        else {
            setCitationStyleJson([
                {
                    name: "A. APA",
                    value: "APA",
                },
                {
                    name: "B. MLA",
                    value: "MLA",
                },
                {
                    name: "C. Chicago",
                    value: "Chicago",
                },
                {
                    name: "D. Harvard",
                    value: "Harvard",
                },
                {
                    name: "E. No particular preference",
                    value: "No particular preference",
                },

            ])
            jobPostProofReaderFormik.setFieldValue("citationstyle", "")
            if (stylseSheetFile) {
                cancelAction()
                jobPostProofReaderFormik.setFieldValue("styleSheetFile", null)

            }
        }
    }, [jobPostProofReaderFormik.values.specService])


    const uploadJobFiles = async (data) => {
        let uploadJobData = await uploadStyleSheetFile(data[0]);
        if (uploadJobData?.serverResponse?.code === 200) {
            console.log(uploadJobData?.result?.data?.responseData?.location, "uploadJobData")
            jobPostProofReaderFormik.setFieldValue("styleSheetFile", uploadJobData?.result?.data?.responseData)
        }

        if (uploadJobData?.result) {
            setStyleSheetFile(uploadJobData?.result?.data);
        }
        // uploadJobToParent()

    };
    const removeItem = async () => {
        let data = {
            key: stylseSheetFile?.responseData?.key,
        };
        if (data) {
            let { serverResponse } = await removeFileFromS3(data);
            if (serverResponse?.code === 200) {
                setStyleSheetFile(null);

            }
        }

    };
    // const uploadJobToParent = async () => {
    //     let apiData = {
    //         jobFile: stylseSheetFile?.responseData
    //     }
    //     let jobUploadData = await uploadCompletedJob(apiData)
    //     toast.success(jobUploadData?.serverResponse?.message);
    //     dispatch(
    //         setJobs({
    //             jobs: null,

    //         })
    //     );
    //     dispatch(startLoading());
    //     // let data = await getAllJobs("all", currentPage);
    //     // if (data?.data?.length > 0) {
    //     //     dispatch(
    //     //         setJobs({
    //     //             jobs: data?.data,
    //     //             pageNumber: data?.pageNumber,
    //     //             totalJobCount: data?.dataCount,
    //     //         })
    //     //     );
    //     // }
    //     dispatch(stopLoading());

    // }
    const cancelAction = () => {
        removeItem()
        jobPostProofReaderFormik.setFieldValue("styleSheetFile", null)


    }
    return (
        <>
            <ResponsiveStepper goNext={jobPostProofReaderFormik.handleSubmit} />
            <div className="Proofreading">
                <form onSubmit={jobPostProofReaderFormik.handleSubmit}>
                    <h3>Proofreading</h3>
                    <div className="gray-box">
                        <div className="radio-li">
                            <h4>1. Choose specific service</h4>
                            <ul>
                                {specificServiceProofreaderJson.map((item, index) => (
                                    <li key={index}>
                                        <div className="radio-group">
                                            <input onChange={jobPostProofReaderFormik.handleChange} type="radio" name="specService" id="specService" value={item.value} checked={jobPostProofReaderFormik.values.specService === item?.value} />
                                            <label htmlFor="html">

                                                <h5>{item.name}</h5>
                                            </label>
                                        </div>
                                    </li>
                                ))}



                            </ul>
                            <p className='form-error-message'>{jobPostProofReaderFormik.touched.specService && jobPostProofReaderFormik.errors.specService}</p>

                            <p className="text-end"> *(Price 1.5x)</p>
                            <div className="row">
                                {stylseSheetFile ? (jobPostProofReaderFormik.values.specService === 'Proofreading + Stylesheet Adherence' && <table className="table table-responsive">
                                    <thead>
                                        <tr>
                                            <th colSpan="2">File name</th>

                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="2">
                                                <h6>
                                                    <span>
                                                        <i className="fas fa-file-alt"></i>
                                                    </span>
                                                    {stylseSheetFile?.responseData?.name}
                                                </h6>
                                            </td>

                                            <td>
                                                <p onClick={removeItem}>
                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>) : jobPostProofReaderFormik.values.specService === 'Proofreading + Stylesheet Adherence' && (<div className="col">
                                    <div className="form-group file-upload">
                                        <DragDropUploadButton
                                            multiple={false}
                                            onChange={uploadJobFiles}
                                            btnCss={"upload-f"}
                                            btnName={t("quick-solution-sec-upload")}
                                        />
                                    </div>
                                </div>)}

                            </div>
                        </div>
                    </div>
                    <div className="gray-box2">
                        <div className="radio-li">
                            <h4>Which Citation style do you prefer?</h4>
                            <ul>
                                {newCitationStyleJson.map((item, index) => (
                                    <li key={index}>
                                        <div className="radio-group">
                                            <input onChange={jobPostProofReaderFormik.handleChange} type="radio" name="citationstyle" id="citationstyle" value={item.value} checked={jobPostProofReaderFormik.values.citationstyle === item?.value} />
                                            <label htmlFor="html">

                                                <h5>{item.name}</h5>
                                            </label>
                                        </div>
                                    </li>
                                ))}

                            </ul>

                        </div>
                        <p className='form-error-message'>{jobPostProofReaderFormik.touched.citationstyle && jobPostProofReaderFormik.errors.citationstyle}</p>

                    </div>
                    <div className="gray3">
                        <h4>3. Choose the required English Dialect</h4>
                        <div className="form-group mb-3">
                            <select name="dialect" id="dialect" onChange={jobPostProofReaderFormik.handleChange} className="form-select proofreading-lang-select" aria-label="Default select example">
                                {proffReaderEngilshDialectJson.map((item, index) => (
                                    <option key={index} value={item.value}><img src={item.images} alt="" />{item.name}</option>

                                ))}

                            </select>
                        </div>
                        <p className='form-error-message'>{jobPostProofReaderFormik.touched.dialect && jobPostProofReaderFormik.errors.dialect}</p>

                    </div>
                    <div className="gray3">
                        <h4>4. Which of the following best describes the intended style of writing?</h4>
                        <div className="radio-li">
                            <ul>
                                {proffReaderWritingStyleJson.map((item, index) => (
                                    <li key={index}>
                                        <div className="radio-group">
                                            <input onChange={jobPostProofReaderFormik.handleChange} name="styleOfWriting" id="styleOfWriting" type="radio" value={item.value} checked={jobPostProofReaderFormik.values.styleOfWriting === item?.value} />
                                            <label htmlFor="html">

                                                <h5>{item.name}</h5>
                                            </label>
                                        </div>
                                    </li>
                                ))}



                            </ul>

                        </div>
                        <p className='form-error-message'>{jobPostProofReaderFormik.touched.styleOfWriting && jobPostProofReaderFormik.errors.styleOfWriting}</p>

                    </div>

                    <div className="gray3 t-gray">
                        <h4>5. Do you have any specific requirements for verb tenses? If so, please specify in <u>the box below</u>. Most research
                            typically follows the tenses shown below.</h4>
                        <div className="gray3-table">
                            <table className="table">
                                <thead>
                                    <tr>

                                        <th>Chapter</th>
                                        <th>Verb tense</th>
                                        <th>Example</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>

                                        <td>Abstract
                                        </td>
                                        <td>Present</td>
                                        <td>This study investigates the impact of A on B.
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>Introduction</td>
                                        <td>Present and Future</td>
                                        <td>This research will explore the relationship between X and Y.
                                        </td>
                                    </tr>

                                    <tr>

                                        <td>Literature Review</td>
                                        <td>Past and Present</td>
                                        <td>Smith (2000) found that A affects B.

                                        </td>
                                    </tr>

                                    <tr>

                                        <td>Methodology</td>
                                        <td>Future</td>
                                        <td>The study will utilize a mixed-methods approach.

                                        </td>
                                    </tr>

                                    <tr>

                                        <td>Results</td>
                                        <td>Past</td>
                                        <td>The results indicated a significant difference between groups.
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>Discussion</td>
                                        <td>Present and Past</td>
                                        <td>The data suggests that there is a correlation between X and Y.
                                        </td>
                                    </tr>



                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="e-ch">
                        <form action="">
                            <div className="form-group">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox"
                                        onChange={jobPostProofReaderFormik.handleChange}
                                        name="showWatermark"
                                        id="showWatermark" checked={jobPostProofReaderFormik.showWatermark} />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        <strong>Show  Lingvara certified proofreading watermark on all
                                            pages? <a download target='_blank' href="/js/Proofreading Watermark.pdf">See Example.</a></strong>
                                    </label>
                                </div>

                            </div>
                            <div className="form-group">
                                <label htmlFor="">Any additional comments to the proofreader?</label>
                                <textarea onChange={jobPostProofReaderFormik.handleChange} value={jobPostProofReaderFormik.values.addComments} name="addComments" id="addComments" cols="10" rows="7" className="form-control"></textarea>
                                <p className='form-error-message'>{jobPostProofReaderFormik.touched.addComments && jobPostProofReaderFormik.errors.addComments}</p>
                            </div>
                        </form>
                    </div>
                    {/* <div className="form-group">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox"
                                name="showWatermark"
                                onClick={handleReward}
                                checked={useReward}
                                id="showWatermark" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                <strong>Use reward points?( {user?.rewardPoint} USD )</strong>
                            </label>
                        </div>

                    </div> */}
                    <div className="button-set">

                        <button type='submit' className="next-button text-center mt-1 ml-2 btn">Submit</button>
                    </div>
                </form>


            </div>
        </>
    )
}

export default ProofReaderConfigure