import React, { useEffect, useRef, useState } from 'react'
import { getTimeAgo } from '../../../utils/getTimeAgoTimer';
import TranslatorManagerConfig from './TranslatorManagerConfig';
import OrderDetailsModal from './OrderDetailsModal';
import useOutsideObserver from "../../../utils/outsideObserver/outsideObserver";
import { useDispatch, useSelector } from 'react-redux';
import { setJobs, setSelectedJobDetails } from '../../../redux/reducers/jobSlice';
import { directPostJob } from '../../../services/api/jobPostApis';
import { startLoading, stopLoading } from '../../../redux/reducers/loadingSlice';
import { deliverApi, getAllJobs, unAssignUser } from '../../../services/api/jobApis';
import SplitedJobPost from './SplitedJobPost';
import JobAssignModal from '../../../components/common/assignJob/JobAssignModal';
import FileUploadModal from '../../../components/common/fileUpload/FileUploadModal';
import ChangePriceConfirmModal from '../../../components/common/changePriceConfirmModal/ChangePriceConfirmModal';
import QRCodeComponent from './QRCodeComponent ';
const SingleJobItem = ({ item, index }) => {
    const dispatch = useDispatch()
    const threeDotRef = useRef(null)
    const configurationRef = useRef(null)
    const { currentPage } = useSelector(
        (state) => state.job
    );
    const [timeAgo, setTimeAgo] = useState("");
    const [showOrderModal, setShowOrderModal] = useState({ open: false })
    const [showAssignModal, setShowAssignModal] = useState(false)
    const [showUploadedFileModal, setShowUploadedFileModal] = useState(false)

    const [showThreeDot, setShowThreeDot] = useState(false)
    const [showConfiguration, setShowConfiguration] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [itemData, setItemData] = useState({})

    useOutsideObserver(threeDotRef, () => setShowThreeDot(false))
    useOutsideObserver(configurationRef, () => setShowConfiguration(false))
    // console.log(item?.orderNumber);
    // console.log(item?.jobStatusForPM == 'delivered' || item?.jobStatusByUser != 'none');
    
    
    useEffect(() => {
        const intervalId = setInterval(() => {
            const timeAgo = getTimeAgo(item?.jobStatusForPM == 'delivered' || item?.jobStatusByUser != 'none' ? 0 : item?.deadline);
            setTimeAgo(timeAgo);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [item?.deadline]);
    const handleConfiguration = (data) => {
        setShowConfiguration(true)
        dispatch(setSelectedJobDetails({ selectedJob: data }))
    }
    // direct post job as manager
    const postJob = async (price) => {
        dispatch(startLoading());
        let diractJobPostData = await directPostJob(itemData)
        if (diractJobPostData?.serverResponse?.code === 201) {
            dispatch(setJobs({ jobs: null, pageNumber: 1, totalJobCount: currentPage ?? 0 }));
            let data = await getAllJobs("all", 1);
            if (data?.data?.length > 0) {
                dispatch(
                    setJobs({
                        jobs: data?.data,
                        pageNumber: data?.pageNumber,
                        totalJobCount: data?.dataCount,
                    })
                );
            }
        }
        dispatch(stopLoading());
    }

    // Unassign job const unassign job 
    const unassignJob = async (jobId) => {
        dispatch(startLoading());
        let unAssignUserRes = await unAssignUser(jobId)
        if (unAssignUserRes?.serverResponse?.code === 200) {
            dispatch(setJobs({ jobs: null, pageNumber: 1, totalJobCount: currentPage ?? 0 }));
            let data = await getAllJobs("all", 1);
            if (data?.data?.length > 0) {
                dispatch(
                    setJobs({
                        jobs: data?.data,
                        pageNumber: data?.pageNumber,
                        totalJobCount: data?.dataCount,
                    })
                );
            }
        }
        dispatch(stopLoading());
    }

    const handlePriceChangeModal = (item) => {
        setShowModal(true)
        setItemData(item)
    }
    const deliverNotificatiopnHandler = async (id) => {

        let notificationData = await deliverApi(id)
    }
    return (
        <div key={index} className='managerstable-mainDiv'>
            <div className="ta-bdy-wrap">
                <div className="ta-bdy">
                    <div className="tab-2" onClick={() => setShowOrderModal({ open: true })}>
                        <div
                            className="p-a"
                        >
                            {item?.orderNumber ? item.orderNumber : 'N/N'}
                        </div>
                    </div>
                    <div className="tab-2">{item?.wordCount}</div>
                    <div className="tab-2">{timeAgo}</div>
                    {item.qrLink ? <div className="tab-2"><QRCodeComponent qrLink ={item.qrLink}/></div> : <div style={{marginLeft:"40px", marginRight:"55px"}}>"N/A"</div>} 
                    <div className="tab-2">{item?.translateFrom} to {item?.translateTo}</div>
                    <div className="tab-2">{item?.jobStatus}%</div>
                    <div className="tab-2">{item?.segmentsId[0]?.perWordPrice}</div>
                    <div className="tab-2">{item?.segmentsId[0]?.jobStatus == 100 ?  item?.segmentsId[0]?.jobStatusForPM : item?.segmentsId[0]?.isAssignedJob ? item?.assignedUserDetails[0].name : item?.segmented ? 'Unassigned' : 'Job not posted yet'}</div>
                    <div className="tab-2"
                        ref={threeDotRef}
                        onClick={() => setShowThreeDot(true)}>
                        <div
                            className="three-dot"
                        >
                            <img src="/images/three-dots.png" alt="three-dot" />
                            {showThreeDot && <ul
                                className="dropdown-menu" style={{ display: 'block' }}
                            >
                                <li>
                                    <a className="dropdown-item" href={item?.jobFile?.location} download>
                                        Download Uploaded File
                                    </a>
                                </li>
                                {item?.segmentsId?.length === 1 && item?.segmentsId[0]?.isAssignedJob && item?.segmentsId[0].isCompleted && <li>
                                    <a
                                        className="dropdown-item"
                                        href={item?.segmentsId[0]?.jobCompletedFile?.location}
                                        download
                                    >
                                        Download Complete Job File
                                    </a>
                                </li>}
                                {((item?.segmented && item?.segmentsId[0]?.isCompleted && !item?.jobCompletedFile) || (item?.jobStatusByUser === "correction")) && <li onClick={() => setShowUploadedFileModal(true)}>
                                    <p className="dropdown-item" >
                                        Upload

                                    </p>
                                </li>}
                                {(item?.segmented && item?.segmentsId[0]?.isCompleted && item?.jobCompletedFile) && (
                                    <li onClick={() => deliverNotificatiopnHandler(item?._id)}>
                                        <p className="dropdown-item" >
                                            Deliver

                                        </p>
                                    </li>
                                )}
                                {!item?.segmented && <li onClick={() => handleConfiguration(item)}>
                                    <p className="dropdown-item">
                                        Split
                                    </p>
                                </li>}
                                {!item?.segmented && <li onClick={() => handlePriceChangeModal(item)}>
                                    <p className="dropdown-item">
                                        Post
                                    </p>
                                </li>}
                                {(item?.segmentsId?.length === 1 && !item?.segmentsId[0]?.isAssignedJob) && <li onClick={() => setShowAssignModal(true)}>
                                    <p className="dropdown-item" href="#">
                                        Assign
                                    </p>
                                </li>}
                                {(item?.segmentsId?.length === 1 && item?.segmentsId[0]?.isAssignedJob) && !item?.segmentsId[0].jobStatus >= 25 && <li onClick={() => unassignJob(item?.segmentsId[0]?._id)}>
                                    <p className="dropdown-item">
                                        Unassign
                                    </p>
                                </li>}
                            </ul>}
                        </div>
                    </div>
                </div>
                {showConfiguration && <TranslatorManagerConfig configurationRef={configurationRef} />}
                {item?.segmentsId?.length > 0 &&
                    item?.segmentsId.map((value) => {
                        return (
                            <SplitedJobPost unassignJob={unassignJob} item={value} setShowAssignModal={setShowAssignModal} showAssignModal={showAssignModal} orderNumber={item.orderNumber}/>
                        )
                    })}
            </div>
            <OrderDetailsModal item={item} showOrderModal={showOrderModal} setShowOrderModal={setShowOrderModal} whoAssigned={item?.assignedUserDetails[0]?.name}/>
            {showAssignModal && <JobAssignModal setShowAssignModal={setShowAssignModal} showAssignModal={showAssignModal} item={item?.segmentsId[0]} />}
            {showUploadedFileModal && (
                <FileUploadModal
                    showUploadedFileModal={showUploadedFileModal}
                    setShowUploadedFileModal={setShowUploadedFileModal}
                    item={item}
                />
            )}
            <ChangePriceConfirmModal handlePost={postJob} showModal={showModal} setShowModal={setShowModal} item={item} setItemData={setItemData} itemData={itemData} />
        </div>
    )
}

export default SingleJobItem