import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTimeAgo } from '../../../utils/getTimeAgoTimer';
import { timeAgoFunc } from '../../../utils/timeAgoFunc';
import { startLoading, stopLoading } from '../../../redux/reducers/loadingSlice';
import { applyJob, getSingleJob } from '../../../services/api/jobApis';
import { setSelectedJobDetails } from '../../../redux/reducers/jobSlice';
import { useLocation } from 'react-router-dom';

const JobDetails = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { selectedJobDetails } = useSelector((state) => state.job)
    const [timeAgo, setTimeAgo] = useState("");
    useEffect(() => {
        const intervalId = setInterval(() => {
            const timeAgo = getTimeAgo(selectedJobDetails?.jobStatusForPM == 'delivered' || selectedJobDetails?.jobStatusByUser != 'none' ? 0 : selectedJobDetails?.deadline);
            setTimeAgo(timeAgo);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [selectedJobDetails?.deadline]);

    useEffect(() => {
        (async () => {
            if (!selectedJobDetails && location?.state?.id) {
                dispatch(startLoading())

                let { data } = await getSingleJob(location?.state?.id)

                if (data) {
                    dispatch(setSelectedJobDetails({ selectedJob: data }))
                }
                dispatch(stopLoading())
            }
        })()
        return () => {
            dispatch(setSelectedJobDetails({ selectedJob: null }))
        }
    }, [])
    const applyForJob = async (jobId) => {
        const apiData = {
            jobId: jobId,
            isAccepted: true
        }
        let data = await applyJob(apiData)
        if (data?.serverResponse?.code === 201) {
            dispatch(startLoading())
            let { data } = await getSingleJob(location?.state?.id)
            if (data) {
                dispatch(setSelectedJobDetails({ selectedJob: data }))
            }
            dispatch(stopLoading())
        }
    }
    return (
        <div className="user-dashbord">
            <div className="user-dtls-jb-pst-page common-padd">
                <div className="container">
                    <div className="user-dtls-jb-pst-wraper">
                        <div className="user-dtls-jb-pst-top">
                            <h4>{selectedJobDetails?.jobTitle}</h4>
                            <ul className="tags-sec">
                                <li className="light-blue-db">
                                    <span>
                                        <img src="/images/icon52.svg" />
                                    </span>{" "}
                                    {timeAgoFunc(selectedJobDetails?.createdAt)}
                                </li>
                                <li className="light-green-db">
                                    <span>
                                        <img src="/images/icon62.svg" />
                                    </span>{" "}
                                    {selectedJobDetails?.wordCount}
                                </li>
                                <li className="light-blue-db">
                                    <span>
                                        <img src="/images/icon61.svg" />
                                    </span>{" "}
                                    {selectedJobDetails?.perWordPrice}
                                </li>
                                <li className="light-green-db">
                                    <span>
                                        <img src="/images/icon60.svg" />
                                    </span>{" "}
                                    {timeAgo}
                                </li>
                            </ul>

                            <h5>Project Overview:</h5>
                            <p>
                                {selectedJobDetails?.comment}
                            </p>
                        </div>
                        <div className="user-dtls-jb-pst-mdl common-btm-mrgn">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4">
                                            <div className="sngl-box">
                                                <h6>Document type:</h6>
                                                <p>{selectedJobDetails?.jobDocumentType}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="sngl-box">
                                                <h6>Language:</h6>
                                                <p>{selectedJobDetails?.translateFrom} to {selectedJobDetails?.translateTo}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="sngl-box">
                                                <h6>Word count:</h6>
                                                <p>{selectedJobDetails?.wordCount}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="user-dtls-jb-pst-end">
                            <h5>Job file:</h5>
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="download-file-box">
                                        <a href={selectedJobDetails?.jobFile?.location} download>
                                            <span>
                                                <img src="/images/icon55.svg" alt="" />
                                            </span>
                                            {selectedJobDetails?.jobFile?.name}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="end-dwnload-two-btn end-dwnload-two-btn-inline">
                            <ul>
                                {!selectedJobDetails?.isAssignedJob && <li onClick={() => applyForJob(selectedJobDetails?._id)}>
                                    <span href="" className="btn">
                                        Apply now
                                    </span>
                                </li>}
                                {/* <li>
                                    <a href={selectedJobDetails?.jobFile?.location} download className="btn btn-border">
                                        <span>
                                            <img src="/images/download.svg" alt="" />
                                        </span>{" "}
                                        Download certificate
                                    </a>
                                </li> */}
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default JobDetails