import React, { useState } from 'react'
import CallToSignup from '../../../components/common/callToSignup/CallToSignup'
import { useNavigate } from 'react-router-dom'
import { setDeadlineOptions, updateBlobFile, updateJob } from '../../../redux/reducers/jobPostSlice'
import DragDropUploadButton from '../../../components/common/DragDropUploadButton'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-bootstrap'
import { getDeadlines } from '../../../services/api/jobPostApis'

const Pricing = () => {
    const navigate = useNavigate()
    const { deadlineOptions } = useSelector((state) => state.jobPost)
    const [serviceType, setServiceType] = useState('translation')
    const [wordRange, setWordRange] = useState("")
    const [estimatedPrice, setEstimatedPrice] = useState(0)
    const { user } = useSelector((state) => state.auth)
    const { t } = useTranslation();
    const dispatch = useDispatch()
    // These are static properties
    // TODO: Need to remove these once we have data from backend
    const staticprice = [
        {
            name: 'translation',
            price: '0.056',
            mainText: "At Lingvara, we offer competitive pricing for our top-notch translation services. Price will vary based on the following factors:",
            subText1: "The language pair you want to translate",
            subText2: "Whether you require certified-specialized translation"
        },
        {
            name: 'proofreading',
            price: '0.012',
            mainText: "At Lingvara, we offer competitive pricing for our meticulous proofreading services. Price will vary based on the following factors:",
            subText1: "How fast you want the proofreading done",
            subText2: "Whether you require adherence to a certain style-sheet"
        }
    ]
    const staticWordCount = ['1-250', '251-2,500', '2501-5,000', '5,001-10,000', '10,001-15,000', '15,001-25,000', '25,001-50,000', '50,001 or more']
    const staticHourCount = [
        {
            time: '24'
        },
        {
            time: '12',
            multiplier: '1.35'
        },
        {
            time: '4',
            multiplier: '2'
        }
    ]
    const uploadJobFiles = (data, serviceName) => {
        dispatch(updateBlobFile({ blobFile: data }))
        dispatch(updateJob({
            type: 'UPDATE_SERVICE_TYPE',
            value: serviceName
        }))
        if (user) {
            navigate('/job-post/upload-file')
        }
        else {
            navigate('/job-post')
        }
    }
    
    let value;
    const handleSubmitPrice = async (e) => {
        e.preventDefault();
         value = e.target.words.value;
       
        setEstimatedPrice(0)
        if (value === '50,001 or more') {
            navigate('/contact-us')
        }
        else {
            dispatch(setDeadlineOptions({ deadlineOptions: null }))
            setWordRange(value)
            let valuesForSpeedOptions = {
                wordCount: value,
                serviceType: serviceType
            }
            let data = await getDeadlines(valuesForSpeedOptions)
            if (typeof data?.result?.data?.speedOptions !== 'string' && data?.result?.data?.speedOptions?.length) {
                dispatch(setDeadlineOptions({ deadlineOptions: data?.result?.data?.speedOptions }))
            }
        }

    }

    const selectServiceType = (item) => {
        setServiceType(item?.name)
        dispatch(setDeadlineOptions({ deadlineOptions: null }))
    }

    const getEstimatedPrice = (value) => {
        const [start, end] = wordRange.split('-').map(numStr => parseInt(numStr.replace(/,/g, ''), 10));
        setEstimatedPrice((value?.fixedRate ? { start: value?.fixedRate } : ({ start: (value?.perWordPrice * value?.multipliedBy * start).toFixed(2), end: (value?.perWordPrice * value?.multipliedBy * end).toFixed(2) })))
    }

    return (
        <div>
            {/* banner section */}
            <section className="home-banner inner-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/Pricing-banner.jpg" alt="" />
                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <div
                                    className="banner-text-wrap"
                                    data-aos="fade-up"
                                    data-aos-duration={3000}
                                >
                                    <h1>Pricing</h1>
                                    <p>
                                        At Lingvara, we provide exceptional value for our premium translation and proofreading services.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* banner end */}
            <section className="Pricing-sec pb-5 padding-top">
                <div className="container">
                    <div className="section-heading text-center">
                        <h3>Pricing</h3>
                    </div>
                    <div className="row">
                        {staticprice.map((item, index) => <div key={index} className="col-lg-6" data-aos="fade-right" data-aos-offset={300} >
                            <div className="Pricing-col-wrap" style={{
                                backgroundColor: serviceType === item?.name && "#9EB212",
                                border: serviceType === item?.name && "1px solid #9EB212",
                                color: serviceType === item?.name && 'white'
                            }} onClick={() => selectServiceType(item)}>
                                <h3>{item?.name.charAt(0).toUpperCase() + item?.name.slice(1)}</h3>
                                <h2 style={{ color: serviceType === item?.name && 'white' }}>
                                    {item?.price} <sub>USD/Word(BASE PRICE)</sub>
                                </h2>
                                <p style={{ color: serviceType === item?.name && 'white' }}>
                                    {item?.mainText}
                                </p>
                                <ul>
                                    <li>{item?.subText1}</li>
                                    <li>{item?.subText2}</li>
                                </ul>
                                <form action="upload.php" method="POST">
                                    <DragDropUploadButton btnCss={"upload-f"} onChange={(data) => uploadJobFiles(data, item?.name)} multiple={false} btnName={t("quick-solution-sec-upload")} />
                                    {/* <button type="submit" className="">Upload</button> */}
                                </form>
                            </div>
                        </div>)}
                    </div>
                    {/* <div className="pricing-price">

                     <div className="Pricing-col-wrap bg-transparent  w-50 text-center mx-auto" >
                     <h6>To calculate the estimated price, please enter the word count of your document and then choose a speed option.</h6>
                    <form 
                    onSubmit={(e) => handleSubmitPrice(e)}
                    // onSubmit={handleSubmit} 
                    className="pricing-form">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label htmlFor="wordRangeInput">Enter the number of words:</label> 
                                <input
                                    type="text"
                                    id="wordRangeInput"
                                    className="form-control w-100"
                                    placeholder="e.g., 1000"
                                    value={wordRange}
                                    name= "words"
                                    onChange={(e) => setWordRange(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary w-100">Calculate Price</button>
                            </div>
                        </div>
                    </div>
                </form> 
                    </div> 
                    </div> */}

                    {/* <div className="pricing-word-sec">
                        <h3>{wordRange && wordRange + "  " + "words"} </h3>
                        <div className="row">
                            {deadlineOptions && deadlineOptions?.length ? deadlineOptions?.map((item, index) => <div key={index} className="col-lg-4 col-md-6">
                                <div className="pricing-word-c" onClick={() => getEstimatedPrice(item)}>

                                    <h4>{item?.time} {item?.timeIn}</h4>
                                    {
                                        item.fixedRate ?
                                            <p>{item?.fixedRate}$ <br /> Flat Rate</p> :
                                            <p>{Math.round(item?.perWordPrice * item?.multipliedBy * wordRange)}$  <br /> Estimated Price</p>

                                    }

                                     <p>{item?.perWordPrice} {item?.multipliedBy && `* ${item?.multipliedBy}`}</p> 
                                </div>
                            </div>) :
                                wordRange && deadlineOptions === null && <div className="pricing-word-c">
                                    <h4>Time options not available this word range</h4>

                                </div>
                            }
                        

                        </div>



                        {(estimatedPrice?.start && deadlineOptions && deadlineOptions?.length) ? <div className="row mt-4">
                            <div className="col-lg-12">
                                <div className="text-center"><p><h3>Estimated Price:</h3> <span>{estimatedPrice?.start}$ {estimatedPrice?.end && `- ${estimatedPrice?.end}$`}</span></p></div>
                            </div>
                        </div> : null}

                    </div> */}
                </div>
            </section>
            <CallToSignup />
        </div>
    )
}

export default Pricing