import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTimeAgo } from '../../../utils/getTimeAgoTimer';
import { timeAgoFunc } from '../../../utils/timeAgoFunc';
import { useLocation, useNavigate } from 'react-router-dom';
import { chatAccess } from '../../../services/api/chatRoomApis';
import { getChatRoomMessage, joinChat } from '../../../services/socket';
import { setActiveroom } from '../../../redux/reducers/chatRoomSlice';
import { getReview, getSingleJob, unAssignUser } from '../../../services/api/jobApis';
import { startLoading, stopLoading } from '../../../redux/reducers/loadingSlice';
import { setReviewRating, setSelectedJobDetails } from '../../../redux/reducers/jobSlice';
import SplitedJobChat from './SplitedJobChat';
import ReviewRating from '../../../components/common/review/ReviewRating';

const MyJobDetailsTransManager = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { selectedJobDetails } = useSelector((state) => state.job)
    const { ratingReview } = useSelector((state) => state.job);
    const { user } = useSelector((state) => state.auth)
    const [timeAgo, setTimeAgo] = useState("");
    const location = useLocation()
    useEffect(() => {
        const intervalId = setInterval(() => {
            const timeAgo = getTimeAgo(selectedJobDetails?.jobStatusForPM == 'delivered' || selectedJobDetails?.jobStatusByUser != 'none' ? 0 : selectedJobDetails?.deadline);
            setTimeAgo(timeAgo);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [selectedJobDetails?.deadline]);
    useEffect(() => {
        (async () => {
            if (!selectedJobDetails && location?.state?.id) {
                dispatch(startLoading())
                let { data } = await getSingleJob(location?.state?.id)
                if (data) {
                    dispatch(setSelectedJobDetails({ selectedJob: data }))
                }
                dispatch(stopLoading())
            }
        })()
        return () => {
            dispatch(setSelectedJobDetails({ selectedJob: null }))
        }
    }, [])
    useEffect(() => {
        (async () => {
            if (selectedJobDetails?._id) {
                let jobReviewData = await getReview(selectedJobDetails?._id)
                if (jobReviewData?.serverResponse?.code === 200) {
                    dispatch(setReviewRating({ reviewRating: jobReviewData.result?.data }))

                }
            }

        })()
    }, [selectedJobDetails?._id])
    const gotoChat = async () => {
        let accessData = await chatAccess(selectedJobDetails?.jobCreatorId)
        if (accessData?.serverResponse?.code === 200) {
            joinChat([accessData?.result[0]?._id, user?._id])
            dispatch(setActiveroom({ activeRoom: accessData?.result[0] })) // will put this to socket event when roomid and userid will be send in an object
            getChatRoomMessage({ userId: user?._id, chatRoomId: accessData?.result[0]?._id, pageNumber: 1, dataPerPage: 10 })
        }
        navigate('/translator-manager-chat')
    }
    // Unassign job const unassign job
    const unassignJob = async (jobId) => {
        dispatch(startLoading());
        let unAssignUserRes = await unAssignUser(jobId);
        if (unAssignUserRes?.serverResponse?.code === 200) {
            let { data } = await getSingleJob(location?.state?.id)
            if (data) {
                dispatch(setSelectedJobDetails({ selectedJob: data }))
            }
        }
        dispatch(stopLoading());
    };
    return (
        <div className="user-dashbord">
            <div className="user-dtls-jb-pst-page common-padd">
                <div className="container">
                    <div className="user-dtls-jb-pst-wraper">
                        <div className="user-dtls-jb-pst-top">
                            <h4>
                                {selectedJobDetails?.jobTitle}
                                <span className="chat" onClick={gotoChat}>
                                    <p>
                                        <img src="/images/chat.svg" alt="" />
                                    </p>
                                </span>
                            </h4>
                            <ul className="tags-sec">
                                <li>
                                    <span>
                                        <img src="/images/icon51.svg" />
                                    </span>{" "}
                                    Translation
                                </li>
                                <li className="light-blue-db">
                                    <span>
                                        <img src="/images/icon52.svg" />
                                    </span>{" "}
                                    {timeAgoFunc(selectedJobDetails?.createdAt)}
                                </li>
                                <li className="light-green-db">
                                    <span>
                                        <img src="/images/icon53.svg" />
                                    </span>{" "}
                                    {selectedJobDetails?.chooseTranslator}
                                </li>
                            </ul>
                            <h5>Project Overview:</h5>
                            <p>
                                {selectedJobDetails?.comment}
                            </p>
                        </div>
                        <div className="user-dtls-jb-pst-mdl common-btm-mrgn">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4">
                                            <div className="sngl-box">
                                                <h6>Document type:</h6>
                                                <p>{selectedJobDetails?.jobDocumentType}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="sngl-box">
                                                <h6>Language:</h6>
                                                <p>{selectedJobDetails?.translateFrom} to {selectedJobDetails?.translateTo}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="sngl-box">
                                                <h6>Word count:</h6>
                                                <p>{selectedJobDetails?.wordCount}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="sngl-box">
                                        <h6>Deadline:</h6>
                                        <p>{timeAgo}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`download-job`}>
                            <div className="user-dtls-jb-pst-end">
                                <h5>Job file:</h5>
                                <div className="row">
                                    <div className="">
                                        <div className="download-file-box">
                                            <a href={selectedJobDetails?.jobFile?.location} download>
                                                <span>
                                                    <img src="/images/icon55.svg" alt="" />
                                                </span>
                                                {selectedJobDetails?.jobFile?.name}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(selectedJobDetails?.jobCompletedFile || selectedJobDetails?.jobStatusByUser !== "none") && <div className="user-dtls-jb-pst-end">
                                <h5>Download file:</h5>
                                <div className="row">
                                    <div className="">
                                        <div className="download-file-box">
                                            <a href={selectedJobDetails?.jobCompletedFile?.location} download>
                                                <span>
                                                    <img src="/images/icon55.svg" alt="" />
                                                </span>
                                                {selectedJobDetails?.jobCompletedFile?.name}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                        </div>

                    </div>
                </div>
                <div className='container'>
                    {selectedJobDetails?.segmentsId?.length > 0 &&
                        selectedJobDetails?.segmentsId.map((value) => {
                            return (
                                <SplitedJobChat item={value} unassignJob={unassignJob} orderNumber={selectedJobDetails.orderNumber}/>
                            )
                        })}
                </div>
                <div className='container'>
                    {selectedJobDetails?.recheckComments &&
                        <>
                            <h4>Recheck comment by customer</h4>
                            <div className='text-container'>{selectedJobDetails?.recheckComments}</div>
                        </>}
                </div>
                <div className='container'>
                    {ratingReview && ratingReview?._id && <ReviewRating ratingReview={ratingReview} />}

                </div>
            </div>

        </div>

    )
}

export default MyJobDetailsTransManager