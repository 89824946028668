import React, { useEffect, useRef, useState } from "react";
import {
    citationStyleJson,
    proffReaderWritingStyleJson,
    specificServiceProofreaderJson,
} from "../../../constants/config";
import { getTimeAgo } from "../../../utils/getTimeAgoTimer";
import useOutsideObserver from "../../../utils/outsideObserver/outsideObserver";
import JobAssignModal from "../../../components/common/assignJob/JobAssignModal";
import { getChatRoomMessage, joinChat } from "../../../services/socket";
import { setActiveroom } from "../../../redux/reducers/chatRoomSlice";
import { chatAccess } from "../../../services/api/chatRoomApis";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const SplittedJobChatProf = ({ item, unassignJob, orderNumber }) => {
    const [showConfiguration, setShowConfiguration] = useState(false);
    const [timeAgo, setTimeAgo] = useState("");
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)
    const dispatch = useDispatch()

    const [showAssignModal, setShowAssignModal] = useState(false);
    const expandButtonRef = useRef();
    useOutsideObserver(expandButtonRef, () => setShowConfiguration(false));
    useEffect(() => {
        const intervalId = setInterval(() => {
            const timeAgo = getTimeAgo(item?.jobStatusForPM == 'delivered' || item?.jobStatusByUser != 'none' ? 0 : item?.deadline);
            setTimeAgo(timeAgo);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [item?.deadline]);
    const gotoChat = async () => {
        let accessData = await chatAccess(item?.assignedUserId)
        if (accessData?.serverResponse?.code === 200) {
            joinChat([accessData?.result[0]?._id, user?._id])
            dispatch(setActiveroom({ activeRoom: accessData?.result[0] })) // will put this to socket event when roomid and userid will be send in an object
            getChatRoomMessage({ userId: user?._id, chatRoomId: accessData?.result[0]?._id, pageNumber: 1, dataPerPage: 10 })
        }
        navigate('/proofreader-manager-chat')
    }
    return (
        <div className="acc-wrap-pm" ref={expandButtonRef}>
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className="accordion-button"
                            type="button"

                        >

                            <h4>
                                {item?.jobTitle}

                            </h4>
                            <div className="button-table btn-table">
                                <div className="button-table-head">
                                    <div className="tab-2">#Order</div>
                                    <div className="tab-2">Wordcount</div>
                                    <div className="tab-2">Deadline</div>
                                    <div className="tab-2">Type of doc</div>
                                    <div className="tab-2">Language</div>
                                    <div className="tab-2">Price/word</div>
                                    <div>
                                        {showConfiguration ? (
                                            <div
                                                data-aos="fade-up"
                                                data-aos-offset={100}
                                                data-aos-easing="ease-in-sine"
                                                onClick={() => setShowConfiguration(!showConfiguration)}
                                            >
                                                <img src={"/images/acc-arrow1.png"} height={40} />
                                            </div>
                                        ) : (
                                            <div
                                                data-aos="fade-up"
                                                data-aos-offset={100}
                                                data-aos-easing="ease-in-sine"
                                                onClick={() => setShowConfiguration(!showConfiguration)}
                                            >
                                                <img src={"/images/acc-arrow2.png"} height={40} />
                                            </div>
                                        )}
                                        {item?.isAssignedJob && <span className="chat" onClick={gotoChat}>
                                            <p>
                                                <img src="/images/chat.svg" alt="" />
                                            </p>
                                        </span>}
                                    </div>
                                </div>

                                <div className="button-table-body">
                                    <div className="tab-2">
                                        <div
                                            className="p-a"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                        >
                                           {orderNumber?orderNumber:"N/N"}
                                        </div>
                                    </div>
                                    <div className="tab-2">{item?.wordCount ?? "--"}</div>
                                    <div className="tab-2">{timeAgo}</div>
                                    <div className="tab-2">{item?.jobDocumentType}</div>
                                    <div className="tab-2">{item?.requiredEnglishDialect}</div>
                                    <div className="tab-2">
                                        <div className="a-border">{item?.perWordPrice}</div>
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                        </button>
                    </h2>
                    {showConfiguration && (
                        <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            data-aos="fade-down"
                            data-aos-offset={100}
                            data-aos-easing="ease-in-sine"
                        >
                            <div className="accordion-body">
                                <div className="acc-body-item">
                                    <div className="item-acc-1">
                                        <div className="signin-form-wrap">
                                            <div className="row">
                                                {/* <div className="col-lg-4">
                            <div className="item-acc-wrap">
                              <div className="form-group">
                                <label for="">Select deadline</label>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  name="deadline"
                                  id="deadline"
                                  onChange={(e) => {
                                    selectDeadLine(e);
                                  }}
                                  disabled={!jobFiles}
                                >
                                  {deadlineStaticArray.map((val, idx) => (
                                    <option
                                      selected={
                                        val?.value ===
                                        item?.values?.deadline
                                      }
                                    >
                                      {val.time}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div> */}
                                                <div className="col-lg-6">
                                                    <div className="item-acc-wrap">
                                                        <div className="form-group">
                                                            <label htmlFor="">Type of document</label>
                                                            <select
                                                                className="form-select"
                                                                aria-label="Default select example"
                                                                disabled
                                                            >
                                                                <option selected="">
                                                                    {item?.jobDocumentType}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="item-acc-wrap">
                                                        <div className="form-group">
                                                            <label for="">Word count</label>
                                                            <input
                                                                type="text"
                                                                placeholder={500}
                                                                className="form-control"
                                                                value={item?.wordCount}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="file-list-table">
                                                    <div className="signin-form-wrap e-ch upload-file-section">
                                                        <div className="form-group">
                                                            <label>Name</label>
                                                            <input
                                                                type="text"
                                                                placeholder=""
                                                                id="jobTitle"
                                                                name="jobTitle"
                                                                className="form-control"
                                                                value={item?.jobTitle}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Add comments?</label>
                                                            <textarea
                                                                name="addComments"
                                                                id="addComments"
                                                                cols="10"
                                                                rows="7"
                                                                className="form-control"
                                                                value={item?.addComments}
                                                                disabled
                                                            ></textarea>
                                                        </div>
                                                        <table className="table table-responsive">
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan="2">File name</th>
                                                                    <th scope="col">Word Count</th>
                                                                    <th scope="col"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan="2">
                                                                        <h6>
                                                                            <span>
                                                                                <i className="fas fa-file-alt"></i>
                                                                            </span>
                                                                            {item?.jobFile?.name}
                                                                        </h6>
                                                                    </td>
                                                                    <td>{item?.wordCount}</td>
                                                                </tr>


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <div className="row-section">
                                                    <h5>Choose specific service:</h5>
                                                    <div className="radio-li half-radio-li">
                                                        <ul>
                                                            {specificServiceProofreaderJson.map(
                                                                (val, idx) => (
                                                                    <li>
                                                                        <div className="radio-group">
                                                                            <input
                                                                                type="radio"
                                                                                name="radio-group3"
                                                                                id="test4"
                                                                                value={val.value}
                                                                                disabled
                                                                                checked={item?.proofReadingService}
                                                                            />
                                                                            <label for="test4">
                                                                                <h5>{val.name}</h5>
                                                                            </label>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <h5>Which Citation style do you prefer?</h5>
                                                        <div className="row">
                                                            {citationStyleJson.map((val, idx) => (
                                                                <div className="col-lg-4 mb-3">
                                                                    <div className="rdio-btn-for-db-frm">
                                                                        <input
                                                                            type="radio"
                                                                            id="test4"
                                                                            disabled
                                                                            name="radio-group2"
                                                                            value={val.value}
                                                                            checked={item?.citationStyle}
                                                                        />
                                                                        <label for="test4">{val.name}</label>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="item-acc-wrap">
                                                        <div className="form-group">
                                                            <label htmlFor="">
                                                                <h5>Choose the required English Dialect</h5>
                                                            </label>
                                                            <select
                                                                className="form-select"
                                                                aria-label="Default select example"
                                                                disabled
                                                            >
                                                                <option
                                                                    selected={item?.requiredEnglishDialect}
                                                                    value={1}
                                                                >
                                                                    {item?.requiredEnglishDialect}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row-section">
                                                    <h5>
                                                        Which of the following best describes the intended
                                                        style of writing?
                                                    </h5>
                                                    <div className="col-lg-12">
                                                        <div className="radio-li half-radio-li">
                                                            <ul>
                                                                {proffReaderWritingStyleJson.map((val, idx) => (
                                                                    <li>
                                                                        <div className="radio-group">
                                                                            <input
                                                                                disabled
                                                                                type="radio"
                                                                                name="plan"
                                                                                checked={item?.intendedStyle}
                                                                                value={val.value}
                                                                            />
                                                                            <label for="html">
                                                                                <h5>{val.name}</h5>
                                                                            </label>
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className="pm-tr-button">
                                                        {/* <button type="submit" className="btn">
                                Post
                              </button> */}
                                                        {!item?.isAssignedJob && <button
                                                            type="submit"
                                                            className="btn btn-border-g"
                                                            onClick={() => setShowAssignModal(true)}
                                                        >
                                                            Assign
                                                        </button>}


                                                        {item?.isAssignedJob && (item?.jobStatus < 25) && <button
                                                            type="submit"
                                                            className="btn btn-border-g"
                                                            onClick={() => unassignJob(item?._id)}
                                                        >
                                                            Unassign
                                                        </button>}

                                                        {item?.isCompleted &&
                                                            <table className="table table-responsive">
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan="1">Completed Job File</th>

                                                                        <th scope="col"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tr>
                                                                    <td colSpan="1">
                                                                        <h6>

                                                                            <a href={item?.jobCompletedFile?.location} download>
                                                                                <span>
                                                                                    <img src="/images/icon55.svg" alt="" />
                                                                                </span>
                                                                                {item?.jobCompletedFile?.name}
                                                                            </a>
                                                                        </h6>
                                                                    </td>

                                                                </tr>

                                                            </table>}


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {showAssignModal && (
                <JobAssignModal
                    setShowAssignModal={setShowAssignModal}
                    showAssignModal={showAssignModal}
                    item={item}
                />
            )}
        </div>
    );
};

export default SplittedJobChatProf;
