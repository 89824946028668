import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setActiveStep, setJobPrice, setSelectedSpeedOption, updateJob } from '../../../redux/reducers/jobPostSlice'
import { deadlineStaticArray } from '../../../constants/config'
import { toast } from 'react-toastify';
import ResponsiveStepper from './ResponsiveStepper'

const Deadline = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { uploadFiles, blobFile, jobWordCount, deadlineOptions, jobPrice, deadlineTime, perWordPrice, configTranslation } = useSelector((state) => state.jobPost)
    const [deadline, setDeadline] = useState({ deadline: deadlineTime, perWordPrice: perWordPrice, multipliedBy: 0, fixedRate: 0 })
    const selectDeadline = (value, price, perWordPrice) => {
        dispatch(setSelectedSpeedOption({ speedOption: value }))
        setDeadline({ perWordPrice: perWordPrice, deadline: `${value?.time} ${value?.timeIn === 'hours' ? 'hr' : 'days'}`, fixedRate: (value?.fixedRate ?? 0), multipliedBy: value?.multipliedBy })
         
        let normalLanguages = {
            arabic: true,
            english: true,
            british: true, 
            american: true,
            standard: true,
            colloquial: true,
            "american english": true,
            "british english": true,
            "standard arabic": true,
            "colloquial arabic": true,

        } 

        if(configTranslation?.fromLang && configTranslation?.toLang){
            const fromLanguage = configTranslation.fromLang.toLowerCase();
            const toLanguage = configTranslation.toLang.toLowerCase();
            if(!normalLanguages[fromLanguage] || !normalLanguages[toLanguage]){
                price = Math.round(Number(price.toFixed(4)) * 1.2);
                dispatch(setJobPrice({
                    jobPrice: price
                }))
            }}
        if (configTranslation?.chooseTranslator === "Subject Specialized Translator"){
            dispatch(setJobPrice({
                jobPrice: Math.round(Number(price.toFixed(4)) * 1.2)
            }))
        }else{

            dispatch(setJobPrice({
                jobPrice: Math.round(Number(price.toFixed(4)))
            }))
        }
    }
    // this use effect handle active route
    useEffect(() => {
    }, [])
    const gotoNextPage = () => {
        if (deadline?.deadline) {
            dispatch(updateJob({
                type: 'UPDATE_DEADLINE',
                value: { deadlineTime: deadline.deadline, perWordPrice: deadline?.perWordPrice, fixedRate: deadline.fixedRate, multipliedBy: deadline.multipliedBy }
            }))
            navigate('/job-post/payment');
        }
        else {
            toast.error("please choose deadline")
        }

    }
    const gotoPrevious = () => {
        dispatch(setActiveStep({ activeStep: 'configure' }))
        navigate('/job-post/config')
    }
    return (
        <>
            <ResponsiveStepper goNext={gotoNextPage} goPrevious={gotoPrevious} />
            <div className="card2 show">
                <h3>Details:</h3>
                <div className="Details-card-r">

                    <div className="Details-card-box">
                        <p>document name</p>
                        <h6>{blobFile?.name}</h6>
                    </div>
                    <div className="Details-card-box">
                        <p>Word count</p>
                        <h6>{jobWordCount}</h6>
                    </div>
                    <div className="Details-card-box">
                        <p>Price</p>
                        <h6><a>{jobPrice} USD</a></h6>
                    </div>


                </div>

                <div className="deadline-servi">
                    <h4>Select deadline:</h4>
                    <div className="row se-d-line">
                        {deadlineOptions && deadlineOptions.map((item, index) => <div className="col-lg-4 col-md-6" key={index}>
                            <div className="radio-group">
                                <input type="radio" name="plan" value={item._id} onChange={(e) => selectDeadline(item, item?.fixedRate ? item?.fixedRate : (item?.perWordPrice * item?.multipliedBy * jobWordCount), item?.perWordPrice)} checked={`${deadline.deadline}` === `${item?.time} ${item?.timeIn === 'hours' ? 'hr' : 'days'}`} />
                                <label htmlFor="html">
                                    <div className="pricing-word-c text-center">
                                        <h4>{item?.time} {item?.timeIn}</h4>
                                        <p>Price x {item?.multipliedBy}</p>
                                        {/* <p>{item?.fixedRate ? `${item?.fixedRate} USD` : `${item?.perWordPrice}USD * ${item?.multipliedBy}`}</p> */}
                                    </div>
                                </label>
                            </div>
                        </div>)}

                    </div>
                    <div className="button-set">
                        <div onClick={gotoPrevious} className="Previous-button  text-center mt-1 ml-2 btn btn-border-black">Previous
                        </div>
                        <div onClick={gotoNextPage} className="next-button text-center mt-1 ml-2 btn">Submit
                        </div> 
                    </div>

                </div>
            </div>
        </>
    )
}

export default Deadline