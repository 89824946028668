import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { downloadCertificateFromValidator } from '../../../services/formValidator/downloadCertificateValidator';
import { checkCertificate, downloadCertificate } from '../../../services/api/downloadCertificateApi'
import { save } from 'save-file'
import { useTranslation } from "react-i18next";

const Download = () => {
    const navigate = useNavigate();
    let currentUrl = window.location.href;
    const [searchParams] = useSearchParams();
    const [certificateLink, setCertificateLink] = useState(null)
    const [requiredPinCode, setRequiredPinCode] = useState(false)
    const [allowDownload, setAllowDownload] = useState(false)
    const [downloadLinks, setDownloadLinks] = useState({})
    const { t } = useTranslation();
    const downloadCertificateFormik = useFormik({
        initialValues: {
            pincode: "",
            certificateLink: certificateLink,
        },
        validationSchema: downloadCertificateFromValidator.validationSchema,
        onSubmit: async (values) => {
            values.certificateLink = downloadCertificateFormik.initialValues.certificateLink;
            let serverResponseDownload = await downloadCertificate(values)
            toast.success(serverResponseDownload.message);

            if (serverResponseDownload?.result.data) {
                setAllowDownload(true);
                setDownloadLinks(serverResponseDownload?.result?.data);
            }
        }
    })

    const checkCertificateAccess = async (data) => {
        let serverResponse = await checkCertificate(data)
        // console.log(serverResponse?.result.data.downloadLink);

        if (serverResponse == 'redirect') {
            toast.error('Certificate is not exist');
            navigate('/');
        }
        //pincode required
        else if (!serverResponse) {
            setRequiredPinCode(true);
            setAllowDownload(false);
        }
        //no pincode required
        else {
            if (serverResponse?.result.data) {
                setAllowDownload(true);
                setDownloadLinks(serverResponse?.result?.data);
            }
        }

    }
    useEffect(() => {

        let findCertificateLink = searchParams.get('link')
        window.history.pushState({}, '', '/download-certificate?link=' + certificateLink);
        const data = { "certificateLink": findCertificateLink };

        if (findCertificateLink != null) {

            (async () => {
                downloadCertificateFormik.values.certificateLink = findCertificateLink;
                setCertificateLink(findCertificateLink)
                try {
                    let downloadLink = await checkCertificateAccess(data)
                } catch (err) {
                    toast.error('Error occured when fetching file');
                }
            })();
        }

    }, []);

    return (
        <>
            <section className="user-dashbord sec_pad">
                <div className="container">
                    <div className="signin-form-wrap">
                        {requiredPinCode && !allowDownload ?
                            <>
                                <h4>Please enter the certificate PIN code to download</h4>
                                <form onSubmit={downloadCertificateFormik.handleSubmit}>
                                    <input type="hidden" name="certificateLink" value={downloadCertificateFormik?.values.certificateLink} />
                                    <div className="form-group">
                                        <label>PIN code</label>
                                        <input type="text" name="pincode" id="pincode" className="form-control"
                                            value={downloadCertificateFormik?.values.pincode} onChange={downloadCertificateFormik.handleChange} />
                                        <p className='form-error-message'>{downloadCertificateFormik.touched.pincode &&
                                            downloadCertificateFormik.errors.pincode}</p>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn w-100">Download</button>
                                    </div>
                                </form>
                            </>
                            : ''}
                        {allowDownload ?
                            <>
                                <h4>Download Links</h4>
                                <div className="form-group">
                                    <ul>
                                        <li><Link to={downloadLinks?.certificateDownloadLink}>{t("certificate")}</Link></li>
                                        <li><Link to={downloadLinks?.originalFileLink}>{t("original-file")}</Link></li>
                                        <li><Link to={downloadLinks?.translatedFileLink}>{t("returned-file")}</Link></li>
                                    </ul>
                                </div>

                            </>
                            : ''}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Download;