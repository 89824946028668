import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { serviceTypeStaticArray } from '../../../constants/config'
import { setActiveStep, setDeadlineOptions, updateJob, setSelectedSpeedOption, setJobPrice} from '../../../redux/reducers/jobPostSlice'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import ResponsiveStepper from './ResponsiveStepper'
import { getBusinessDeadlines, getDeadlines } from '../../../services/api/jobPostApis'
const GeneralService = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { serviceType, deadlineTime, jobWordCount } = useSelector((state) => state.jobPost)
    const { user }=useSelector((state) => state.auth)
    const [servicesType, setServicesType] = useState(serviceType)
    const selectServiceType = (value) => {
        setServicesType(value.target.value)
    }
    const selectDeadline = (value, price, perWordPrice) => {
        dispatch(setSelectedSpeedOption({ speedOption: value }))
        dispatch(setJobPrice({
            jobPrice: Number(price.toFixed(4))
        }))
    }
    const gotoNext = async () => {
        let deadLineValues = {
            wordCount: Number(jobWordCount),
            serviceType: servicesType
         }
          let data = user.role === "company" ? await getDeadlines(deadLineValues) : await getDeadlines(deadLineValues)
          if (typeof data?.result?.data?.speedOptions !== 'string' && data?.result?.data?.speedOptions?.length){
            let allSpeedOption = data?.result?.data?.speedOptions
            const lowestSpeedOption = allSpeedOption.reduce((minObj, currentObj) => 
            {
                const calculatedFirst = currentObj.perWordPrice ?  Number(currentObj.perWordPrice) * Number(jobWordCount) : Number(currentObj.fixedRate);
                const calculatedSecond = minObj.perWordPrice ?  Number(minObj.perWordPrice) * Number(jobWordCount) : Number(minObj.fixedRate);
                return calculatedFirst < calculatedSecond ? currentObj : minObj
            }
            
        );
        selectDeadline(lowestSpeedOption, lowestSpeedOption?.fixedRate ? lowestSpeedOption?.fixedRate : (lowestSpeedOption?.perWordPrice * lowestSpeedOption?.multipliedBy * jobWordCount), lowestSpeedOption?.perWordPrice)
          }

            if (servicesType){
                dispatch(setActiveStep({ activeStep: 'configure' }))
                navigate('/job-post/config')
                dispatch(updateJob({
                    type: 'UPDATE_SERVICE_TYPE',
                    value: servicesType
                }))
            }
            else {
                toast.error("please choose one service type")
            }
    }
    const goPreviousPage = () => {
        dispatch(setActiveStep({ activeStep: 'typeOfDoc' }))
        navigate('/job-post/document-type')
    }
    return (
        <>
            <ResponsiveStepper goNext={gotoNext} goPrevious={goPreviousPage} />
            <div className="card2 show">
                <h4>Choose General Service type:</h4>
                <div className="g-servi">
                    <div className="row">
                        {serviceTypeStaticArray.map((item, index) => <div key={index} className="col-lg-6">
                            <div className="radio-group">
                                <input type="radio" name="plan" value={item?.value} onChange={selectServiceType} checked={servicesType === item?.value} />
                                <label htmlFor="html">
                                    <img src={item?.image} alt="" />
                                    <h5>{item?.name}</h5>
                                </label>
                            </div>
                        </div>)}

                    </div>
                    <div className="button-set">
                        <div onClick={goPreviousPage} className="Previous-button  text-center mt-1 ml-2 btn btn-border-black">Previous
                        </div>
                        <div onClick={gotoNext} className="next-button text-center mt-1 ml-2 btn">Next
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default GeneralService